import React, { useRef } from 'react'
import CheckBox from '../CheckBox/CheckBox'

const RowSelectionCheckbox = React.forwardRef(({ indeterminate, disabled, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const labelref = useRef(null)
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    React.useEffect(() => {
        const parentnode = labelref.current.parentNode
        if (parentnode && rest.checked) {
            parentnode.className = 'react-table-td-checkbox-checked'
        }
        return () => {
            if (parentnode) {
                parentnode.className = ''
            }
        }
    }, [rest.checked])

    return <CheckBox labelref={labelref} label='' REF={resolvedRef} {...rest} labelClassName={'react-table-row-select'} disabled={disabled} />
})

export default RowSelectionCheckbox
