import { stateList } from "../../../../../../Components/GST/stateData"
import { invoiceSummaryCalc } from "../../../../../../Recoil/Invoice/HelperFunc"
import { getDiscountedPrice, getDiscountedPriceByUnit, getPriceOnRateAndTax, getRateOnPrice } from "../../../../../General/DiscountCals"
import { toDateString } from "../../../../../General/GetDateTime"
import { numToFixed2, numToIfFixed2 } from "../../../../../General/numManipulation"
import { iNRWords } from "../../../../../General/numberToWords"
import { objKeyToArr, objReduce } from "../../../../../General/objectManipulation"
import { getBase64ImageFromURL, writeTextForPDF } from "../../../PDFFunctions"
import { invoicePageFooter, invoicePageHeader, invoicePageWaterMark } from "../PDFInvoiceHelperFunc"

const invoiceBusinessDataPDF = ({ invoiceData, businessData, businessLogo }) => {
    const { invoicegstin } = invoiceData
    const { gSTData = {} } = businessData
    const { businessname = businessData.businessData, address = '', mobile = '' } = (gSTData?.[invoicegstin] ?? {})
    const stack = []
    stack.push({
        text: businessname ?? businessData.businessname ?? '',
        // italics: true,
        bold: true, fontSize: 16
    })
    if (address)
        stack.push({ text: (address + '').replace('\n', ', ') })
    if (invoicegstin) {
        const statecode = (invoicegstin + '').substring(0, 2)
        stack.push({ text: 'GSTIN : ' + invoicegstin, bold: true })
        stack.push({ text: 'State Code :' + statecode + ' (' + (stateList[statecode]) + ')', bold: true })
    }
    if (mobile)
        stack.push({ text: 'Mobile : ' + mobile })
    const logo = businessLogo ? {
        image: businessLogo,
        width: 80,
        // height: 144,
        // absolutePosition: { x: 480, y: 160 },
        alignment: 'left',
    } : {}

    return {
        columns: [
            { ...logo },
            { stack, alignment: 'center', margin: [0, 20, 80, 0] },
        ],
    }

}

const invoiceHeader = ({ invoiceData, businessData, businessLogo }) => {
    const invoiceBusinessDataPDF = () => {
        const { invoicegstin } = invoiceData
        const { gSTData = {} } = businessData
        const { businessname = businessData.businessData, address = '', mobile = '' } = (gSTData?.[invoicegstin] ?? {})
        const stack = []
        stack.push({
            text: businessname ?? businessData.businessname ?? '',
            // italics: true,
            bold: true, fontSize: 16
        })
        if (address)
            stack.push({ text: (address + '').replace('\n', ', ') })
        if (invoicegstin) {
            const statecode = (invoicegstin + '').substring(0, 2)
            stack.push({ text: 'GSTIN : ' + invoicegstin, bold: true })
            stack.push({ text: 'State Code :' + statecode + ' (' + (stateList[statecode]) + ')', bold: true })
        }
        if (mobile)
            stack.push({ text: 'Mobile : ' + mobile })
        const logo = businessLogo ? {
            image: businessLogo,
            width: 80,
            // height: 144,
            // absolutePosition: { x: 480, y: 160 },
            alignment: 'left',
        } : {}

        return {
            columns: [
                { ...logo },
                { stack, alignment: 'center', margin: [0, 20, 80, 0] },
            ],
            margin: [0, 20, 0, 0]
        }
    }

    const invoiceDataPDF = () => {
        const { by = '', invoicenumber = '', timestamp = '', ots = '', oid = '' } = invoiceData
        return {
            columns: [
                {
                    stack: [
                        { text: 'Invoice Details: ', bold: true, fontSize: 13 },
                        { text: 'No: ' + invoicenumber, fontSize: 13 },
                        { text: 'Date:' + toDateString(timestamp), fontSize: 13 },
                        'Invoice By:' + by,
                    ],
                },
            ]
        }
    }

    const invoiceCustomerDataPDF = () => {
        const { invoiceCustomerData = {}, invoicegstin = '', customerAdditionalData = {} } = invoiceData
        const { customername, customermobile, customergst, customeraddress, } = invoiceCustomerData

        const stack = [
            { text: 'Billed To', bold: true, fontSize: 13 }
        ]
        // const stack = [{ text: customername ? 'Buyer' : 'Guest' }]
        // if (customername)
        stack.push({
            text: customername || 'Guest',
            // italics: true,
            bold: true, fontSize: 15
        })
        if (customeraddress)
            stack.push({ text: customeraddress })
        if (customergst) {
            const statecode = (customergst + '').substring(0, 2)
            stack.push({ text: 'GSTIN : ' + customergst, bold: true })
            stack.push({ text: 'State Code :' + statecode + ' (' + (stateList[statecode]) + ')', bold: true })
        }
        if (customermobile)
            stack.push({ text: 'Mobile : ' + customermobile })


        return { stack }
    }

    const invoiceCustomerAdditionalData = () => {
        try {

            const { customerAdditionalData = {} } = invoiceData

            const stack2 = []
            const { model, brand, regno, km } = customerAdditionalData
            if (model || brand) {
                stack2.push({ text: 'Vehicle Details : ', bold: true, fontSize: 13 })
                stack2.push({ text: `${brand} ${model}` })
            }
            if (regno)
                stack2.push({ text: `RegNo: ${regno}` })
            if (km)
                stack2.push({ text: `KM: ${km}` })

            return { stack: stack2 }
        } catch (error) {
            console.error({ error });
        }
    }

    // const invoiceShipmentDataPDF = () => {
    //     const { invoiceShipment = {} } = invoiceData
    //     const { transportby = '', vehicle = '', } = invoiceShipment
    //     return {
    //         stack: [
    //             { text: 'Shipping Address :' },
    //             { text: 'Vehicle No : ' + vehicle }
    //         ],
    //     }
    // }

    return {
        stack: [
            invoiceBusinessDataPDF(),
            {
                columns: [
                    invoiceCustomerDataPDF(),
                    invoiceCustomerAdditionalData(),
                    invoiceDataPDF(),
                ],
                margin: [0, 10, 0, 10]
            }
        ]
    }
}

const invoiceItemsPDF = ({ items = [], sno = 0, itemscount = 15, islast = false, defaultUnits, isparts = false, isigst = false }) => {
    const invoiceSummary = invoiceSummaryCalc(items)

    const getItemsHeader = () => {
        return [
            { text: 'S.NO', bold: true },
            { text: isparts ? 'Parts Description' : 'Labour Description', bold: true },
            { text: 'HSN', bold: true },
            { text: 'Quantity', bold: true },
            [
                { text: 'Rate', bold: true, alignment: 'center' },
                { text: 'Incl.GST', italic: true, alignment: 'center' },
            ],
            { text: 'GST Rate', bold: true },
            { text: 'Amount', bold: true }
        ]
    }

    const getItemsArray = () => {
        return items.reduce((acc, crr, index) => {
            const { name, des, hsncode, gstrate, quantity, price, unitid, discount, cessrate, pcode } = crr
            const unitname = defaultUnits?.[unitid]?.unitname ?? ''
            // const total = getDiscountedPriceByUnit(price, discount, quantity)
            const totaltaxrate = Number(Number(gstrate) + Number(cessrate)).toFixed(2)
            const rate = getDiscountedPrice(getRateOnPrice(getDiscountedPrice(price, discount), totaltaxrate))
            const total = rate * quantity
            // const total = getPriceOnRateAndTax(rate * quantity, numToIfFixed2(gstrate, cessrate))
            // const rate = getDiscountedPrice(getRateOnPrice(price, numToIfFixed2(gstrate, cessrate)))
            // const total = getPriceOnRateAndTax(getDiscountedPriceByUnit(rate, discount, quantity), numToIfFixed2(gstrate, cessrate))
            return [
                ...acc,
                [
                    { text: index - (-1) - (-sno), alignment: 'right' },
                    { text: writeTextForPDF(`${name} ${des ? `\n ${des}` : ''}${pcode ? `\n${pcode}` : ''}`) },
                    // { text: writeTextForPDF(name + (des ? (" -" + des) : '')) },
                    { text: hsncode },
                    { text: Number(quantity).toFixed(3), alignment: 'right' },
                    { text: Number(price).toFixed(2), alignment: 'right' },
                    { text: Number(gstrate).toFixed(2), alignment: 'right' },
                    { text: Number(total).toFixed(2), alignment: 'right' }
                ]
            ]
        }, [])
    };

    // const emptyLines = () => {
    //     const emptyline = () => ['\n', '', '', '', '', '', '', '', '', '', '\n']
    //     let emptyLinesArr = []
    //     if (items.length < itemscount) {
    //         const emptylinecount = itemscount - items.length - taxCount - 1
    //         emptyLinesArr = Array.from({ length: emptylinecount }, () => '').map(() => emptyline())
    //     }
    //     return emptyLinesArr
    // }

    const isLast = () => {
        const { invoicetotaltax, invoicetotal } = invoiceSummary
        if (islast) {
            if (isigst)
                return [
                    ['\n', '', '', '', '', '', ''],
                    ['\n', '', '', '', '', '', ''],
                    ['', '', { text: '@igst', alignment: 'right', bold: true }, '', '', '', { text: numToFixed2(invoicetotaltax), bold: true, alignment: 'right' }],
                    ['\n', '', '', '', '', '', ''],
                    [{ text: 'Total', alignment: 'right', bold: true, colSpan: 5, margin: [0, 0, 30, 0] }, {}, {}, { text: '', alignment: 'right', bold: true }, '', '', { text: invoicetotal, bold: true, alignment: 'right' }],
                ]
            return [
                ['\n', '', '', '', '', '', ''],
                ['', '', { text: '@cgst', alignment: 'right', bold: true }, '', '', '', { text: numToFixed2(invoicetotaltax / 2), bold: true, alignment: 'right' }],
                ['', '', { text: '@sgst', alignment: 'right', bold: true }, '', '', '', { text: numToFixed2(invoicetotaltax / 2), bold: true, alignment: 'right' }],
                ['\n', '', '', '', '', '', ''],
                [{ text: 'Total', alignment: 'right', bold: true, colSpan: 5, margin: [0, 0, 30, 0] }, {}, {}, { text: '', alignment: 'right', bold: true }, '', '', { text: invoicetotal, bold: true, alignment: 'right' }],
            ]
        }
        return []
    }

    return {
        table: {
            widths: [23, '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
            // widths: ['*','*','*','*','*','*','*','*','*'],
            headerRows: 1,
            body: [
                getItemsHeader(),
                ...getItemsArray(),
                // ...emptyLines(),
                ...isLast()
            ],
        },
        layout: {
            hLineWidth: function (i, node) {
                if (i === 0)
                    return 1
                if (i === node.table.headerRows)
                    return 1;
                if (i === node.table.body.length)
                    return 1;
                if ((i === node.table.body.length - 1) && (islast))
                    return 1;
                return 0;
            },
            vLineWidth: function () {
                return 1;
            },
        }
    }
}

const invoiceFooter = ({ items = [], businessData = {}, invoiceData = {}, receiptSettings = {}, isend = false, isigst = false }) => {
    try {

        const invoiceSummary = invoiceSummaryCalc(items)
        const { invoicetotal, invoicetotaltax, invoiceratebasedtax } = invoiceSummary

        const invoiceAmountInWords = (amount = 1000, istaxamount = false,) => {
            return [
                { text: istaxamount ? 'Tax Amount (in words) :' : 'Amount Chargeable (in words) :' },
                { text: iNRWords(amount), bold: true }
            ]
        }

        const invoiceTaxPDF = () => {
            const getTaxArray = () => {
                // const { invoiceratebasedtax } = invoiceSummaryCalc(invoiceData.softInvoiceItems)

                return objReduce(invoiceratebasedtax, (acc, taxrate) => {
                    const { cgst, sgst, gst, totaltax, taxableamount } = invoiceratebasedtax[taxrate]
                    if (isigst)
                        return [
                            ...acc,
                            [
                                { text: taxrate + '%', alignment: 'center' },
                                { text: taxableamount, alignment: 'right' },
                                { text: numToFixed2(taxrate) + '%', alignment: 'center' },
                                { text: numToFixed2(totaltax), alignment: 'right' },
                                { text: numToFixed2(totaltax), alignment: 'right' },
                            ]
                        ]
                    return [
                        ...acc,
                        [
                            { text: taxrate + '%', alignment: 'center' },
                            { text: taxableamount, alignment: 'right' },
                            { text: numToFixed2(taxrate / 2) + '%', alignment: 'center' },
                            { text: numToFixed2(totaltax / 2), alignment: 'right' },
                            { text: numToFixed2(taxrate / 2) + '%', alignment: 'center' },
                            { text: numToFixed2(totaltax / 2), alignment: 'right' },
                            { text: numToFixed2(totaltax), alignment: 'right' },
                        ]
                    ]
                }, [])
            }

            // return {}

            const taxHeader = () => {
                if (isigst)
                    return [
                        [
                            { text: 'Tax Rate', rowSpan: 2, alignment: 'center', bold: true },
                            { text: 'Taxable Value', rowSpan: 2, alignment: 'center', bold: true },
                            { text: 'Integrated Tax', colSpan: 2, alignment: 'center', bold: true },
                            {},
                            { text: 'Total Tax Amount', rowSpan: 2, alignment: 'center', bold: true },
                        ],
                        [
                            {},
                            {},
                            { text: 'Rate', alignment: 'center', bold: true },
                            { text: 'Amount', alignment: 'center', bold: true },
                            {},
                        ],
                    ]

                return [
                    [
                        { text: 'Tax Rate', rowSpan: 2, alignment: 'center', bold: true },
                        { text: 'Taxable Value', rowSpan: 2, alignment: 'center', bold: true },
                        { text: 'Central Tax', colSpan: 2, alignment: 'center', bold: true },
                        {},
                        { text: 'State Tax', colSpan: 2, alignment: 'center', bold: true },
                        {},
                        { text: 'Total Tax Amount', rowSpan: 2, alignment: 'center', bold: true },
                    ],
                    [
                        {},
                        {},
                        { text: 'Rate', alignment: 'center', bold: true },
                        { text: 'Amount', alignment: 'center', bold: true },
                        { text: 'Rate', alignment: 'center', bold: true },
                        { text: 'Amount', alignment: 'center', bold: true },
                        {},
                    ],
                ]
            }

            const getTaxTotal = () => {
                if (isigst)
                    return [{ text: 'Total', alignment: 'right', bold: true, colSpan: 4, margin: [0, 0, 10, 0] }, '', '', '', { text: invoicetotaltax, bold: true, alignment: 'right' }]
                return [{ text: 'Total', alignment: 'right', bold: true, colSpan: 6, margin: [0, 0, 10, 0] }, '', '', '', '', '', { text: invoicetotaltax, bold: true, alignment: 'right' }]
            }

            return {
                table: {
                    headerRows: 2,
                    widths: isigst ? ['*', '*', '*', '*', '*'] : ['*', '*', '*', '*', '*', '*', '*'],
                    body: [
                        ...taxHeader(),
                        ...getTaxArray(),
                        getTaxTotal(),
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        if (i === 0)
                            return 1
                        if (i <= node.table.headerRows)
                            return 1;
                        if (i === node.table.body.length)
                            return 1;
                        if (i === node.table.body.length - 1)
                            return 1;
                        return 0;
                    },
                    vLineWidth: function () {
                        return 1;
                    }
                }
            }

        }

        const invoiceFooterDecleration = () => {
            const { decleration = '' } = receiptSettings
            return {
                stack: [
                    {
                        text: 'Remarks :',
                        // italics: true, 
                        bold: true,
                        heights: 50
                    },
                    {
                        text: '\n\n\n\n\n\n\n\n',
                    }
                ],
                alignment: 'left',
                heights: 40
                // margin: [0,0,0,0], 
                // border: [true, true, true, true],
            }
        }

        const invoiceFooterBankDetails = () => {
            const { bankid, invoicegstin = '' } = invoiceData
            // const bankDetails = bankid ? allBankData[bankid] : allBankData[objKeyToArr(allBankData)[0]]
            // const { bankname, accname, accno, ifsc, upiid } = bankDetails
            const gstBankData = invoicegstin ? businessData.gSTData[invoicegstin] : businessData.gSTData[objKeyToArr(businessData.gSTData)[0]]
            const { bankname = '', ifsc = '', accno = '', upiid = '' } = gstBankData
            return {
                stack: [
                    {
                        text: 'Bank Details',
                        // italics: true, 
                        bold: true
                    },
                    'Name :' + bankname,
                    // 'Account Name :' + accname,
                    'Account Number:' + accno,
                    'IFSC Code:' + ifsc,
                    'UPI ID: ' + upiid,
                ],
                // margin:[0,50,0,0], 
                // border: [false, true, true, true],
            }
        }

        const invoiceFooterCustomerSignatory = () => {
            return {
                text: 'Customer\'s Seal and Signature',
                alignment: 'left',
                // margin: [0,0,0,0], 
                // border: [true, true, true, true],
            }
        }

        const invoiceFooterBusinessSignatory = () => {
            const { gSTData = {} } = businessData
            return {
                text: `For ${gSTData?.[invoiceData?.invoicegstin || '-']?.businessname ?? businessData.businessname}\n\n\n\n\nAuthorised Signatory`,
                alignment: 'right',
                bold: true,
                margin: [0, 100, 0, 0],
                // border: [false, true, true, true],
            }
        }

        const endTable = isend ? {
            columns: [
                {
                    stack: [
                        invoiceFooterDecleration(),
                        invoiceFooterBankDetails(),
                    ]
                },
                invoiceFooterBusinessSignatory(),
            ],
            margin: [0, 20]
        } : {}

        if (isend) return endTable

        return {
            // absolutePosition: { x: 40, y: 520 },
            stack: [
                {
                    columns: [
                        { stack: [...invoiceAmountInWords(invoicetotal)], },
                        { text: 'E. & O.E', alignment: 'right', },
                    ]
                },
                invoiceTaxPDF(),
                ...invoiceAmountInWords(invoicetotaltax, true),
                { ...endTable }
            ]
        }
    } catch (error) {
        console.error({ error });
    }
}


export const serviceInvoicePDF1 = (data) => {

    const {
        invoiceData,
        businessData,
        coll = 'sales',
        receiptSettings,
        defaultUnits,
        businessLogo,
    } = data


    const planPDFByPartsAndService = () => {
        const { softInvoiceItems = [], invoicegstin, invoiceCustomerData } = invoiceData
        const invoicestatecode = (invoicegstin + '').substring(0, 2)
        const customerstatecode = invoiceCustomerData.customergst ? (invoiceCustomerData.customergst + '').substring(0, 2) : ''
        const isigst = customerstatecode ? (invoicestatecode !== customerstatecode) : false

        const { serviceItems, partsItems } = softInvoiceItems.reduce((acc, crr) => {
            if (crr.isservice ?? false)
                return { ...acc, serviceItems: [...acc.serviceItems, crr] }
            return { ...acc, partsItems: [...acc.partsItems, crr] }
        }, { serviceItems: [], partsItems: [] })

        const invoiceSummary = invoiceSummaryCalc(softInvoiceItems)

        let pdfPages = [
            invoiceHeader({ businessData, invoiceData, businessLogo }),
        ]

        if (partsItems.length) {
            // pdfPages.push({ ...invoiceBusinessDataPDF({ invoiceData, businessData, businessLogo }), pageBreak: 'before' })
            pdfPages.push({ text: 'Parts Summary', alignment: 'center', fontSize: 15, bold: true, margin: [0, 20, 0, 0] })
            pdfPages.push(invoiceItemsPDF({ items: partsItems, defaultUnits, islast: true, allitems: partsItems, isparts: true, isigst }))
            // pdfPages.push({ text: 'Parts Tax', fontSize: 13, bold: true, })
            pdfPages.push(invoiceFooter({ items: partsItems, businessData, invoiceData, receiptSettings, isigst }))
        }

        if (serviceItems.length) {
            pdfPages.push({ ...invoiceBusinessDataPDF({ invoiceData, businessData, businessLogo }), pageBreak: 'before' })
            pdfPages.push({ text: 'Labour Summary', alignment: 'center', fontSize: 15, bold: true, margin: [0, 20, 0, 0] })
            pdfPages.push(invoiceItemsPDF({ items: serviceItems, defaultUnits, islast: true, allitems: serviceItems, isigst }))
            // pdfPages.push({ text: 'Labour Tax', fontSize: 13, bold: true, })
            pdfPages.push(invoiceFooter({ items: serviceItems, businessData, invoiceData, receiptSettings, isigst }))
        }

        const { invoicetemptaxableamount, invoicetotaltax, invoicetotal, invoiceroundoffdiff, invoicegrosstotal } = invoiceSummary
        pdfPages.push({ ...invoiceBusinessDataPDF({ invoiceData, businessData, businessLogo }), pageBreak: 'before' })
        pdfPages.push({ text: 'Invoice Summary', alignment: 'center', fontSize: 15, bold: true, })
        pdfPages.push({
            columns: [
                {},
                {
                    stack: [
                        'Taxable Amount : ',
                        'Total Tax : ',
                        'Total : ',
                        'Round Off : ',
                        { text: 'Total', fontSize: 15, bold: true }
                    ],
                    alignment: 'right'
                },
                {
                    stack: [
                        invoicetemptaxableamount,
                        invoicetotaltax,
                        invoicetotal,
                        { text: [invoiceroundoffdiff > 0 ? '+' : '', invoiceroundoffdiff] },
                        { text: numToFixed2(invoicegrosstotal), fontSize: 15, bold: true }
                    ],
                    alignment: 'right'
                }
            ],
            margin: [0, 20],
        })
        pdfPages.push(invoiceFooter({ items: softInvoiceItems, businessData, invoiceData, receiptSettings, isend: true }))


        return pdfPages
    }

    const dd = {
        footer: function (currentPage, pageCount) {
            return invoicePageFooter({ currentPage, pageCount, receiptSettings })
        },
        header: function (currentPage, pageCount, pageSize) {
            return invoicePageHeader({ invoicetype: invoiceData.invoicetype, coll })
        },
        watermark: invoicePageWaterMark({ receiptSettings }),
        content: [...planPDFByPartsAndService()],
        // content: [{ text: 'hi' }],
        defaultStyle: {
            fontSize: 10,
            margin: 5,
        }
    }

    return dd

}
