
export const getDiscountPercentage = (price = 0, discountper = 0) => {
    if (Number(price) === 0) return 0
    const discountpercentage = (100 - ((Number(discountper) / Number(price)) * 100))
    return Number(discountpercentage).toFixed(2)
}

export const getDiscountPercentageByDiscountPrice = (price = 0, discount = 0) => {
    if (!price) return 0
    return Number((discount / price) * 100).toFixed(2)
}

export const getDiscountedPrice = (price = 0, percentage = 0) => {
    const discountedprice = ((Number(price).toFixed(2) / 100) * (100 - Number(percentage).toFixed(2)))
    return Number(discountedprice).toFixed(2)
}

export const getDiscountedPriceByUnit = (price = 0, percentage = 0, quantity = 0) => {
    return getDiscountedPrice(price * quantity, percentage)
}

export const getDiscountPercentagePerUnit = (price = 0, discountper = 0, quantity = 1) => {
    if ((Number(price) === 0) || (Number(quantity) === 0) || (Number(discountper) === 0 && Number(quantity) === 0)) return 0
    return getDiscountPercentage(price, discountper / quantity)
}

export const getRateOnPrice = (price = 0, tax = 0) => {
    return Number((Number(price) / (100 + Number(tax))) * 100).toFixed(2)
}

export const getTaxOnRate = (rate = 0, tax = 0) => {
    return ((Number(rate) / 100) * Number(tax)).toFixed(2)
}

export const getPriceOnRateAndTax = (rate = 0, tax = 0) => {
    return (Number(rate) + Number(getTaxOnRate(rate, tax))).toFixed(2)
}

export const getMarginOnPrice = (price = 0, margin = 0, byper = true) => {
    if (byper)
        return Number(Number(price - (-Number(price / 100 * margin))).toFixed(2))
    return Number(Number(price - (-Number(margin))).toFixed(2))
}