import React, { useEffect, useRef, useState } from 'react'
import { useAsyncDebounce } from 'react-table'

function GlobalFilter({ filter, setFilter, data }) {
    const [value, setValue] = useState(filter)
    const globalFilterRef = useRef(null)

    const onChange = useAsyncDebounce(v => {
        setFilter(v || undefined)
    }, 300)

    useEffect(() => {
        onChange(value)
    }, [value])

    // useEffect(() => globalFilterRef.current.focus() || setValue(''), [data])

    return (
        <span>
            <input
                type="search"
                value={value || ''}
                placeholder="Global Search"
                style={{ fontSize: '20px' }}
                onChange={({ target: { value } }) => { setValue(value) }}
                ref={globalFilterRef}
            />
        </span>
    )
}

export default GlobalFilter
