import { atom } from "recoil";

export const businessDataState = atom({
    key: 'businessDataState',
    default: {}
})

export const subscriptionState = atom({
    key: "subscriptionState",
    default: { validtill: {}, limits: {}, tabs: {}, subtabs: {}, features: {}, reports: {} }
})

export const shopDataState = atom({
    key: 'shopDataState',
    default: {}
})

export const delegationState = atom({
    key: 'delegationState',
    default: {}
})

export const rolesState = atom({
    key: 'rolesState',
    default: {},
})

export const shopWalletState = atom({
    key: 'shopWalletState',
    default: 0,
})

export const employeesFromDBState = atom({
    key: 'employeesFromDBState',
    default: {},
})

//business recent orders
export const businessRecentOrders = atom({
    key: 'businessRecentOrders',
    default: []
})

export const businessPendingOrders = atom({
    key: 'businessPendingOrders',
    default: []
})

export const promotionState = atom({
    key: 'promotionState',
    default: {},
})

export const tutorialState = atom({
    key: 'tutorialState',
    default: { youtube: { PNIroQU4M8Q: 'Add Item' } }
})