import React, { useEffect, useState } from "react";
import { getClassNameType } from "../../General/ClassName";


const Tooltip = ({ type, delay, direction, content, children }) => {
    let type2
    if ((type === 'primary') || (type === 'btn--primary')) type = '--default--A'
    if ((type === 'default') || (type === 'btn--default')) type = '--primary--A'
    const [active, setActive] = useState(false);
    const [mouse, setMouse] = useState('')

    useEffect(() => {
        let timeout
        if (mouse === 'showtip')
            timeout = setTimeout(() => setActive(true), delay || 400)
        else if (mouse === 'hidetip') {
            clearInterval(timeout)
            setActive(false)
        }
        return (() => clearInterval(timeout))
    }, [mouse])

    return (
        <div
            className={`Tooltip-Wrapper`}
            // When to show the tooltip
            onMouseEnter={() => setMouse('showtip')}
            onMouseLeave={() => setMouse('hidetip')}
        >
            {/* Wrapping */}
            {children}
            {(active) && (
                <div className={`Tooltip-Tip ${direction || "top"} ${type2 || getClassNameType(type)}`}>
                    {/* Content */}
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
