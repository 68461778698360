import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'

function PDFVIew({ url, setPDFLoading, onLoadSuccess }) {
    const [pages, setPages] = useState(0)

    const pageWidth = 695.28;
    const pageHeight = 941.89;

    const onPrintLoadSuccess = ({ numPages }) => {
        setPages(numPages)
        if (setPDFLoading)
            setPDFLoading(false)
        if (onLoadSuccess)
            return onLoadSuccess()
    }

    useEffect(() => {
        return () => {
            setPages(0)
        }
    }, [])

    return (
        <Document file={url} onLoadSuccess={onPrintLoadSuccess}>
            {
                Array.from({ length: pages }, () => Math.random()).map((num, index) => {
                    return <Page key={num} pageNumber={index - (-1)} style={{ width: pageWidth, height: pageHeight }}
                    width={695.28} height={941.89}
                    />
                })
            }
        </Document>
    )
}

export default React.memo(PDFVIew)