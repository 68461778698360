import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import '../../CommonCss/SideBar.css'
import { IconContext } from 'react-icons';

function SideBar({ sideBarData, sideBarTitle, sideBarIcon }) {
    const [sidebar, setSidebar] = useState(false);
    const [subMenu, setSubMenu] = useState('')

    const showSidebar = () => setSidebar(!sidebar);
    const hideSidebar = () => setSidebar(false);
    const showSubMenu = (name) => setSubMenu(subMenu === name ? '' : name)

    if (!sideBarData) return null

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className="sidebar">
                    <div className='sidebar-header'>
                        <div
                            to='#'
                            className='sidebar-icons'
                            onClick={showSidebar}
                        >
                            <div>
                                {
                                    !sidebar ?
                                        <>
                                            {
                                                sideBarIcon || <FaIcons.FaBars />
                                            }
                                        </>
                                        :
                                        <AiIcons.AiOutlineClose />
                                }
                                <span>
                                    {sideBarTitle || ''}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-container">
                        <nav className={sidebar ? 'sidebar-menu active custom-scrollbar' : 'sidebar-menu custom-scrollbar'}>
                            <ul className='sidebar-menu-items'>
                                {sideBarData.map((item, index) => {
                                    if (item.subMenu) {
                                        return (
                                            <li
                                                key={index}
                                                // className={item.cName}
                                                className='sidebar-item'
                                            >
                                                <div
                                                    className="sidebar-item-title"
                                                    onClick={() => showSubMenu(item.title)}
                                                >
                                                    {item.icon}
                                                    <span>{item.title}</span>
                                                </div>
                                                <div className="flex-box-break"></div>
                                                <div className={subMenu === item.title ? 'sub-menu-container active' : 'sub-menu-container'}>
                                                    <ul
                                                        className={'sub-menu-items'}
                                                        onClick={hideSidebar}
                                                    >
                                                        {
                                                            item.subMenu.map((subItem, subindex) => {
                                                                return (
                                                                    <li
                                                                        key={subindex}
                                                                        className="sub-menu-item"
                                                                    >
                                                                        <Link
                                                                            to={subItem.path}
                                                                            className="sub-menu-links"
                                                                        >
                                                                            {subItem.icon}
                                                                            <span>{subItem.title}</span>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </li>
                                        );
                                    }
                                    else {
                                        return (
                                            <li
                                                key={index}
                                                // className={item.cName}
                                                onClick={hideSidebar}
                                                className='sidebar-item'
                                            >
                                                <Link
                                                    to={item.path}
                                                    className="sidebar-item-title"
                                                >
                                                    {item.icon}
                                                    <span>{item.title}</span>
                                                </Link>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    );
}

export default SideBar;