import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { onlineState, osState } from '../../Recoil/StartUp/Atoms'

function CheckOnline() {
    const setOnline = useSetRecoilState(onlineState)
    const setOs = useSetRecoilState(osState)

    const backOnline = () => setOnline(true)
    const backOffline = () => setOnline(false)

    const getUserAgent = () => {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent))
            return "Windows Phone";

        if (/android/i.test(userAgent))
            return "Android";

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
            return "iOS";

        if (/win/i.test(userAgent))
            return "Windows OS"

        if (/mac/i.test(userAgent))
            return "Mac OS"

        if (/x11/i.test(userAgent))
            return "UNIX OS"

        if (/Linus/i.test(userAgent))
            return "Linux OS"

        return "unknown";
    }

    useEffect(() => {
        window.addEventListener('online', backOnline)
        window.addEventListener('offline', backOffline)

        //user Agent
        setOs(getUserAgent())
        return () => {
            window.removeEventListener('online', null)
            window.removeEventListener('offline', null)
        }
    }, [])

    return null
}

export default CheckOnline
