import { selector, selectorFamily } from "recoil";
import { convertTimestamp } from "../../Utils/General/GetDateTime";
import { getLenOfObjBool } from "../../Utils/General/objectManipulation";
import { authDataState } from "../StartUp/Atoms";
import { userState } from "../User/Atoms";
import { businessDataState, delegationState, rolesState, subscriptionState } from "./Atom";

export const subscriptionValidityState = selector({
    key: 'subscriptionValidityState',
    get: ({ get }) => {
        const { validtill } = get(subscriptionState)
        if (validtill) {
            const enddate = convertTimestamp(validtill)
            const isexpired = new Date(enddate) < new Date()
            const remainingdays = Number((new Date(enddate) - new Date()) / (1000 * 3600 * 24)).toFixed(0)
            return {
                validtill,
                enddate,
                isexpired,
                remainingdays
            }
        }
        return {
            validtill: 0,
            enddate: 0,
            isexpired: true,
            remainingdays: 0,
        }
    }
})

//to react table data
export const delegationTableDataState = selector({
    key: 'delegationTableDataState',
    get: ({ get }) => {
        const delegation = get(delegationState)
        if (Object.keys(delegation).length)
            return [
                ...Object.keys(delegation).reduce((acc, uid) => {
                    return [
                        ...acc,
                        {
                            uid,
                            ...delegation[uid],
                        }
                    ]
                }, [])
            ]
        return []
    }
})

export const rolesTableDataState = selector({
    key: 'rolesTableDataState',
    get: ({ get }) => {
        const roles = get(rolesState)
        if (Object.keys(roles).length)
            return [
                ...Object.keys(roles).reduce((acc, roleid) => {
                    return [
                        ...acc,
                        {
                            roleid,
                            ...roles[roleid],
                        }
                    ]
                }, [])
            ]
        return []
    }
})


export const allBusinessUsersState = selector({
    key: 'allBusinessUsers',
    get: ({ get }) => {
        const businessData = get(businessDataState)
        if (!getLenOfObjBool(businessData)) return {}
        const { ownerid } = businessData
        let data = {
            [ownerid]: { username: "SUPER-ADMIN" }
        }
        const user = get(userState)
        const auth = get(authDataState)
        if ((ownerid === auth.uid) && user.fullname)
            data = { [auth.uid]: { username: user.fullname } }
        const delegatedUsers = get(delegationState)
        if (!getLenOfObjBool(delegatedUsers)) return { ...data }
        return {
            ...data,
            ...delegatedUsers
        }
    }
})

export const allGSTState = selector({
    key: 'allGSTState',
    get: ({ get }) => (get(businessDataState)?.['gSTData']) ?? {}
})

export const getGSTDataState = selectorFamily({
    key: 'getGSTDataState',
    get: reqgstin => ({ get }) => {
        const { gSTData, businessname } = get(businessDataState)
        if (gSTData) {
            if (reqgstin)
                return gSTData[reqgstin] || { gstbillcode: '', address: '', statecode: '', businessname }
            return gSTData[Object.keys(gSTData)[0]]
        }
        return { billcode: 'AA', address: '', statecode: (reqgstin || '').substring(0, 2) }
    }
})
