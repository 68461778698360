export const orderAutomationSettingDefaultData = {
    autoconvert: false,
}

export const defaultOrderAutomationSettingDefaultData = {
    autoconvert: false,
}

export const automationSettingsDefaultData = {
    orderAutomationSetting: orderAutomationSettingDefaultData,
    defaultOrderAutomationSetting: defaultOrderAutomationSettingDefaultData,
}
