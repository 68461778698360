import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination, useRowSelect, useExpanded, useGroupBy } from 'react-table'
import GlobalFilter from './GlobalFilter'
import ColumnFilter from './ColumnFilter'
import RowSelectionCheckbox from './RowSelectionCheckbox'
import Button from '../Button'
import { ReactTableColumnSelectionModal } from './ReactTableColumnSelection'
import { matchSorter } from 'match-sorter'
import { getLenOfObjBool } from '../../General/objectManipulation'
import { VscGroupByRefType, VscUngroupByRefType } from 'react-icons/vsc'
import { FiFilter } from 'react-icons/fi'
import Tooltip from '../../CustomeComponent/Tooltip/Tooltip'
import useQuery from '../../../CustomHooks/ReactRouterQuery/useQuery'

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

function ReactTableContainer({ COLUMNS, DATA, HeaderComponent, FooterComponent, headerProps = {}, footerProps = {}, renderRowSubComponent, selfreportname = '' }) {
    const [columnFilterToggel, setColumnFilterToggel] = useState(false)
    const [isGroupBy, setIsGroupBy] = useState(false)

    const { subtab, id } = useParams()
    const report = useQuery('report')
    const lsname = `ANGADI_reports_${subtab}_${(report || id || '')}_${selfreportname}_`
    const lspagesizename = `${lsname}pageSize`
    const lspageSize = localStorage.getItem(`${lsname}pageSize`)
    const lscolumns = JSON.parse(localStorage.getItem(`${lsname}columns`))

    const authorizedColumn = () => COLUMNS.filter(col => ((col.authorized ?? true) && (col.subscribed ?? true)))
    const getColumns = () => [...authorizedColumn()].filter(col => !!!col.Cell)

    const columns = useMemo(() => isGroupBy ? getColumns() : [...authorizedColumn()], [isGroupBy])
    const data = useMemo(() => [...DATA], [DATA])
    const defaultColumn = useMemo(() => ({ Filter: ColumnFilter }), [])

    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        allColumns,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        setGlobalFilter,
        setAllFilters,
        setGroupBy,
        selectedFlatRows,
        visibleColumns,
        toggleAllRowsSelected,
    } = useTable({
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: {
            hiddenColumns: columns.filter(col => {
                const checkColumns = () => {
                    return col.show === undefined ? false : !col.show
                }
                if (!!lscolumns)
                    return lscolumns[col.accessor] !== undefined ? !lscolumns[col.accessor] : checkColumns()
                return checkColumns()
            }).map(col => col.accessor),
            pageSize: Number(!!lspageSize ? ((lspageSize === 'All') ? data.length : lspageSize) : 10),
        }
    },
        useFilters,
        useGlobalFilter,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <>
                            <RowSelectionCheckbox {...getToggleAllPageRowsSelectedProps()} disabled={isGroupBy} />
                        </>
                    ),
                    Cell: ({ row }) => (
                        <>
                            <RowSelectionCheckbox {...row.getToggleRowSelectedProps()} disabled={isGroupBy} />
                        </>
                    ),
                },
                ...columns,
            ])
        }
    )

    const { globalFilter, pageIndex, pageSize, selectedRowIds, filters, hiddenColumns } = state

    const additionalData = useMemo(() => {
        const isselected = getLenOfObjBool(selectedRowIds)
        const isfiltered = getLenOfObjBool(filters)
        const bothaction = isselected && isfiltered
        const anyaction = isselected || isfiltered
        const resultData = isselected ? selectedFlatRows : rows
        // const resultData = (bothaction || isselected) ? selectedFlatRows : rows
        const tabletitleaddon = `${anyaction ? ' -' : ''} ${isselected ? 'Selected' : ''}${bothaction ? ' & ' : ''}${isfiltered ? 'Filtered' : ''}`
        return { isselected, isfiltered, bothaction, anyaction, resultData, tabletitleaddon }
    }, [selectedFlatRows, rows])

    //const { selectedFlatRows = [], selectedRowIds = {}, resulttitle = '', rows = [], filters = [], isfiltered = false, isselected = false, bothaction = false, anyaction = false, resultData = [], tabletitleaddon = '' } = props
    const disableAllGroupedRows = () => {
        toggleAllRowsSelected(false)
        setGroupBy([])
        setAllFilters([])
        setColumnFilterToggel(false)
        setIsGroupBy(prevState => !prevState)
    }

    const onChangePageSize = (value, length) => {
        setPageSize(Number((value === 'All') ? length : value))
        localStorage.setItem(`${lspagesizename}`, value)
    }

    useEffect(() => {
        const toSetPageSize = localStorage.getItem(`${lspagesizename}`)
        setPageSize(Number(!!toSetPageSize ? ((toSetPageSize === 'All') ? data.length : toSetPageSize) : 10))
    }, [])

    return (
        <div className="react-table-component">
            <div className="table-filters">
                <div className="column-filter">
                    <ReactTableColumnSelectionModal allColumns={allColumns} hiddenColumns={hiddenColumns} lsname={lsname} />
                    <Button
                        type="button"
                        onClick={() => {
                            setAllFilters([])
                            setColumnFilterToggel(prevState => !prevState)
                        }}
                        buttonSize="btn--small"
                        buttonStyle={columnFilterToggel ? "btn--danger" : "btn--special"}
                        buttontext={<>Column <FiFilter /></>}
                        disabled={isGroupBy}
                    />
                    <Tooltip content={`${isGroupBy ? 'Close ' : ''}Group By`} type={isGroupBy ? 'btn--danger' : 'btn--success'} direction={'top'}>
                        <Button
                            onClick={disableAllGroupedRows}
                            buttonSize='btn--small'
                            buttonStyle={isGroupBy ? 'btn--danger' : 'btn--success'}
                            buttontext={isGroupBy ? <VscUngroupByRefType /> : <VscGroupByRefType />}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="pagination">
                <div className="pagination-pagedata">
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong> {' '}
                    </span>
                    <span>
                        | Go to Page: {' '}
                        <input
                            type='number' defaultValue={pageIndex + 1}
                            onChange={({ target: { value } }) => {
                                const pageNumber = value ? Number(value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            style={{ width: '50px' }}
                        />
                    </span>
                </div>
                <div className="pagenation-navigation">
                    <Button
                        type="button"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                        buttonSize="btn--small"
                        buttonStyle="btn--primary"
                    >
                        {'<<'}
                    </Button>
                    <Button
                        type="button"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        buttonSize="btn--small"
                        buttonStyle="btn--primary"
                    >
                        Previous
                    </Button>
                    <select value={[10, 50, 100, 500].find(dt => dt === pageSize) ?? 'All'} onChange={({ target: { value } }) => onChangePageSize(value, data.length)}>
                        {
                            [...new Set([10, 50, 100, 500, 'All'])].map(rowSize => (
                                <option key={rowSize} value={rowSize}>
                                    Show {(rowSize === 'All') ? 'All' : rowSize} Rows
                                </option>
                            ))
                        }
                    </select>
                    <Button
                        type="button"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        buttonSize="btn--small"
                        buttonStyle="btn--primary"
                    >
                        Next
                    </Button>
                    <Button
                        type="button"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                        buttonSize="btn--small"
                        buttonStyle="btn--primary"
                    >
                        {'>>'}
                    </Button>
                </div>
            </div>
            <div className="global-filter-toggel">
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} data={data} />
            </div>
            {
                HeaderComponent &&
                <div className="react-table-header-component">
                    <HeaderComponent
                        data={data} rows={rows}
                        selectedFlatRows={selectedFlatRows} selectedRowIds={selectedRowIds}
                        filters={filters}
                        {...additionalData}
                        {...headerProps}
                    />
                </div>
            }
            <div className="react-table-show">
                <table
                    className="react-table"
                    {...getTableProps()}
                >
                    <thead>
                        {
                            headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>
                                                <div>
                                                    {
                                                        (isGroupBy && column.canGroupBy) ?
                                                            <span {...column.getGroupByToggleProps()}>
                                                                {column.isGrouped ? <VscUngroupByRefType style={{ color: 'red' }} /> : <VscGroupByRefType style={{ color: 'greenyellow' }} />}
                                                            </span>
                                                            : null
                                                    }
                                                    <span {...column.getSortByToggleProps()}>
                                                        {column.render('Header')}
                                                        {column.isSorted ? (column.isSortedDesc ? '↑' : '↓') : ''}
                                                    </span>
                                                </div>
                                                {
                                                    columnFilterToggel &&
                                                    <div>
                                                        {column.canFilter ? column.render('Filter') : null}
                                                    </div>
                                                }
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map(row => {
                                prepareRow(row)
                                const { key, ...rowProps } = row.getRowProps()
                                return (
                                    <React.Fragment key={key}>
                                        <tr {...rowProps}>
                                            {
                                                row.cells.map(cell => {
                                                    return <td
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            background: cell.isGrouped
                                                                ? '#0aff0082'
                                                                : cell.isAggregated
                                                                    ? '#ffa50078'
                                                                    : cell.isPlaceholder
                                                                        ? '#ff000042'
                                                                        : '',
                                                        }}
                                                    >
                                                        {cell.isGrouped ? (
                                                            // If it's a grouped cell, add an expander and row count
                                                            <>
                                                                <span {...row.getToggleRowExpandedProps()}>
                                                                    {row.isExpanded ? '👇' : '👉'}
                                                                </span>{' '}
                                                                {cell.render('Cell')} ({row.subRows.length})
                                                            </>
                                                        ) : cell.isAggregated ? (
                                                            // If the cell is aggregated, use the Aggregated
                                                            // renderer for cell
                                                            cell.render('Aggregated')
                                                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                                            // Otherwise, just render the regular cell
                                                            cell.render('Cell')
                                                        )}
                                                    </td>
                                                })
                                            }
                                        </tr>
                                        {
                                            (row.isExpanded && !!renderRowSubComponent && !isGroupBy) ?
                                                <tr {...rowProps}>
                                                    <td colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })}
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                isGroupBy &&
                <div className="group-by-values-reference">
                    <div
                        style={{
                            padding: '0.5rem 0',
                        }}
                    >
                        <span
                            style={{
                                display: 'inline-block',
                                background: '#0aff0082',
                                padding: '0.5rem',
                            }}
                        >
                            Grouped
                        </span>{' '}
                        <span
                            style={{
                                display: 'inline-block',
                                background: '#ffa50078',
                                padding: '0.5rem',
                            }}
                        >
                            Aggregated
                        </span>{' '}
                        <span
                            style={{
                                display: 'inline-block',
                                background: '#ff000042',
                                padding: '0.5rem',
                            }}
                        >
                            Repeated Value
                        </span>
                    </div>
                </div>
            }
            {
                FooterComponent &&
                <div className="react-table-footer-component">
                    <FooterComponent
                        data={data} rows={rows}
                        selectedFlatRows={selectedFlatRows} selectedRowIds={selectedRowIds}
                        filters={filters}
                        {...additionalData}
                        {...footerProps}
                    />
                </div>
            }
        </div >
    )
}

// const ReactTableContainer = React.memo(ReactTableComp)

export default ReactTableContainer
