import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { allBusinessUsersState } from '../../Recoil/Business/Selector';
import { authDataState } from '../../Recoil/StartUp/Atoms';
import { userState } from '../../Recoil/User/Atoms';
import { defaultBusinessIdState, defaultShopIdState } from '../../Recoil/User/Selector';
import { firebase, fdb, fauth } from './firebase'

function useFSDB() {
    const { uid } = useRecoilValue(authDataState)

    const userData = useRecoilValue(userState)
    const allBusinessUsers = useRecoilValue(allBusinessUsersState)

    const businessid = useRecoilValue(defaultBusinessIdState)

    const shopid = useRecoilValue(defaultShopIdState)

    const firestore = firebase.firestore

    const fbatch = fdb.batch();

    const fTimestamp = firestore.Timestamp

    const ftimestamp = firestore.FieldValue.serverTimestamp()

    const fincrementfieldvalue = firestore.FieldValue.increment(1)

    const fdeletefieldvalue = firestore.FieldValue.delete()

    const forceLogout = () => fauth.signOut()

    const logout = () => {
        if (window.confirm('Are you sure you want to log out ?')) forceLogout()
    }

    const invoiceRef = fdb.collection('invoice')

    const [businessRef, setBusinessRef] = useState(businessid ? fdb.collection('business').doc(businessid) : fdb)
    const [shopRef, setShopRef] = useState(shopid ? fdb.collection('shops').doc(shopid) : fdb)
    const [itemsDatasRef, setItemDatasRef] = useState(() => businessRef.collection('ITEMSDATA'))
    const [avlTypesRef, setAvlTypesRef] = useState(() => businessRef.collection('AVLTYPES'))
    const [saleTypesRef, setSaleTypesRef] = useState(() => businessRef.collection('SALETYPES'))
    const [discountsRef, setDiscountsRef] = useState(() => businessRef.collection('DISCOUNTS'))

    useEffect(() => {
        const newBusiRef = businessid ? fdb.collection('business').doc(businessid) : fdb
        setBusinessRef(newBusiRef)
        setItemDatasRef(() => () => newBusiRef.collection('ITEMSDATA'))
        setAvlTypesRef(() => () => newBusiRef.collection('AVLTYPES'))
        setSaleTypesRef(() => () => newBusiRef.collection('SALETYPES'))
        setDiscountsRef(() => () => newBusiRef.collection('DISCOUNTS'))
        return () => {
            setBusinessRef(() => () => null)
            setItemDatasRef(() => () => null)
            setAvlTypesRef(() => () => null)
            setSaleTypesRef(() => () => null)
            setDiscountsRef(() => () => null)
        }
    }, [businessid])

    useEffect(() => {
        const newShopRef = shopid ? fdb.collection('shops').doc(shopid) : fdb
        setShopRef(newShopRef)
        return () => setShopRef(() => () => null)
    }, [shopid])

    return {
        uid,
        businessid,
        shopid,
        fauth,
        firestore,
        fTimestamp,
        fdb,
        fbatch,
        ftimestamp,
        fincrementfieldvalue,
        fdeletefieldvalue,
        forceLogout,
        logout,
        invoiceRef,
        businessRef,
        shopRef,
        itemsDatasRef,
        avlTypesRef,
        saleTypesRef,
        discountsRef,
        fullname: userData.fullname ?? '',
        username: allBusinessUsers?.[uid]?.username ?? '',
    }
}

export default useFSDB
