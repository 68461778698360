import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { useHotkeys } from 'react-hotkeys-hook'
import Button from '../../CustomElements/Button'
import Tooltip from '../Tooltip/Tooltip'

function ReactModal(
    {
        buttonSize = 'btn--small',
        buttonStyle = 'btn--success',
        buttontext = 'Click Me',
        buttonclass = '',
        ButtComp,
        isopen = false,
        isclose = false,
        onClose,
        tooltip = false,
        showCloseIcon = true,
        closeOnOverlayClick = true,
        closeOnEsc = true,
        closebutton = false,
        closebuttontext = 'close',
        closebuttonstyle,
        hotkeys = { hotkey: 'ctrl+shift+a+b+c+d', enableOnFormTags: ['INPUT', 'SELECT', 'TEXTAREA'], preventDefault: true },
        modelclass = '',
        Component,
        isbutton = true,
        isicon = false,
        ...props
    }
) {

    const { hotkey = 'ctrl+shift+a+b+c+d', enableOnFormTags = ['INPUT', 'SELECT', 'TEXTAREA'], preventDefault = true } = hotkeys
    const [isModalOpen, setIsModalOpen] = useState(isopen)

    useHotkeys(
        hotkey,
        () => setIsModalOpen(true),
        { enableOnFormTags, preventDefault }
    )

    const closeModal = () => {
        setIsModalOpen(false)
        if (onClose) return onClose()
    }

    // useEffect(() => {
    //     document.body.style.overflow = isModalOpen ? 'hidden' : 'unset'
    //     return (() => {
    //         document.body.style.overflow = 'unset'
    //     })
    // }, [isModalOpen])
    useEffect(() => {
        setIsModalOpen(!!isopen)
    }, [isopen])

    useEffect(() => {
        if (isclose)
            closeModal()
    }, [isclose])

    const butComp = () => isbutton ?
        <>
            {
                ButtComp ? <ButtComp onClick={() => setIsModalOpen(true)} /> :
                    <Button
                        type='button'
                        buttonSize={buttonSize}
                        buttonStyle={buttonStyle}
                        onClick={() => setIsModalOpen(true)}
                    >
                        {buttontext || 'Click Me'}
                    </Button>
            }
        </>
        : isicon ?
            <span
                className={buttonclass}
                style={{
                    cursor: 'pointer',
                    margin: '5px'
                }}
                onClick={() => setIsModalOpen(true)}
            >
                {buttontext || 'Click Me'}
            </span>
            : ''

    return (
        <>
            {
                tooltip ? <Tooltip {...tooltip}>{butComp()}</Tooltip>
                    :
                    <>{butComp()}</>
            }
            {
                isModalOpen &&
                <Modal
                    open={isModalOpen}
                    onClose={closeModal}
                    center
                    closeOnOverlayClick={closeOnOverlayClick}
                    closeOnEsc={closeOnEsc}
                    showCloseIcon={showCloseIcon}
                    classNames={{
                        overlay: 'customOverlay',
                        modal: `customModal ${modelclass} hide-scroll-bar`,
                    }}
                >
                    {
                        props.component ?
                            React.cloneElement(props.component, { ...props, closeModal })
                            :
                            ''
                    }
                    {
                        props.children ? props.children : null
                    }
                    {
                        Component ?
                            <Component {...props} closeModal={closeModal} />
                            : ''
                    }
                    {
                        closebutton ?
                            <Button
                                buttonSize={'btn--small'}
                                buttonStyle={closebuttonstyle}
                                buttontext={closebuttontext}
                                onClick={closeModal}
                            />
                            : ''
                    }
                </Modal>
            }
        </>
    )
}

export default ReactModal
