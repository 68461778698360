import { numToFixed2 } from "../../../../General/numManipulation"
import { objKeyToArr } from "../../../../General/objectManipulation";
import { writeTextForPDF, pDFPageNumber } from "../../PDFFunctions"

export const cuttingJobCompletedPDFContent = (data) => {
    const { cuttingData = [], totalpiece, totalcost, avgaoutturn, avgeoutturn, diffinoutturn, diffinoutturnper } = data

    const getTableBody = () => {
        return cuttingData.map((cuttingJob) => {
            const { employee, completedon, garment, fabric, sizesname, color, qty, totalpiece, avgaoutturn, avgeoutturn, totalcost } = cuttingJob
            const diffinoutturn = numToFixed2(avgaoutturn - avgeoutturn)
            const fontColor = (Number(diffinoutturn) >= 0) ? 'green' : 'red'
            return [
                writeTextForPDF(employee, { bold: true }),
                writeTextForPDF(completedon, {}),
                writeTextForPDF(garment, {}),
                writeTextForPDF(fabric, {}),
                writeTextForPDF(sizesname, {}),
                writeTextForPDF(color, {}),
                writeTextForPDF(qty, {}),
                writeTextForPDF(totalpiece, {}),
                writeTextForPDF(totalcost, { bold: true }),
                writeTextForPDF(avgaoutturn, { color: fontColor }),
                writeTextForPDF(avgeoutturn, {}),
            ]
        })
    }

    const content = [
        {
            table: {
                headerRows: 1,
                widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                    //header
                    [
                        { text: 'Employee', bold: true },
                        { text: 'Date', bold: true },
                        { text: 'Item', bold: true },
                        { text: 'Fabric', bold: true },
                        { text: 'Size', bold: true },
                        { text: 'Color', bold: true },
                        { text: 'Weight', bold: true },
                        { text: 'Piece', bold: true },
                        { text: 'Cost', bold: true },
                        { text: 'Out Turn', bold: true },
                        { text: 'E.Out Turn', bold: true },
                    ],
                    //table Body
                    ...getTableBody(),
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    if (i === 0)
                        return 1
                    if (i === node.table.headerRows)
                        return 1;
                    if (i === node.table.body.length)
                        return 1;
                    return 0;
                },
                vLineWidth: function () {
                    return 1;
                },
            }
        },
        {
            text: '\nSummary',
            decoration: 'underline',
            bold: true,
        },
        {
            text: [
                '\nTotal Cost : ',
                { text: totalcost, bold: true }
            ]
        },
        {
            text: [
                'Total Piece : ',
                { text: totalpiece, bold: true }
            ]
        },
        {
            text: [
                'Avg Out Turn : ',
                { text: numToFixed2(avgaoutturn), bold: true }
            ]
        },
        {
            text: [
                'Avg E.Out Turn : ',
                { text: numToFixed2(avgeoutturn), bold: true }
            ]
        },
        {
            text: [
                'Diff In Out Turn : ',
                diffinoutturn,
                ' per KG | ',
                diffinoutturnper,
                ' %'
            ],
            color: (diffinoutturn >= 0) ? 'green' : 'red',
        },
    ]

    return {
        header: function () {
            return { text: 'Cutting Section - Job Completion Report', alignment: 'center', bold: true, fontSize: '18' }
        },
        footer: function (currentPage, pageCount) {
            return pDFPageNumber(currentPage, pageCount, { bold: true })
        },
        content,
    }
}


export const cuttingJobCompletedEmployeeWisePDFContent = (data) => {
    const { cuttingData = [] } = data
    const employeeWise = cuttingData.reduce((acc, jobData) => {
        const { empid } = jobData
        if (acc[empid]) {
            const jobs = [...acc[empid].jobs]
            jobs.push(jobData)
            return { ...acc, [empid]: { ...acc[empid], jobs } }
        }
        return { ...acc, [empid]: { empname: jobData.employee, jobs: [jobData] } }
    }, {})

    const employeeWiseContent = objKeyToArr(employeeWise).map((empid, index) => {
        const employeeWiseData = employeeWise[empid]

        const { totalpiece, totalcost, totalaoutturn, totaleoutturn } = employeeWiseData.jobs.reduce((acc, job) => {
            const { totalpiece = 0, totalcost = 0, avgeoutturn = 0, avgaoutturn = 0 } = job
            return {
                ...acc,
                totalcost: numToFixed2(acc.totalcost, totalcost),
                totalpiece: numToFixed2(acc.totalpiece, totalpiece),
                totalaoutturn: numToFixed2(acc.totalaoutturn, avgaoutturn),
                totaleoutturn: numToFixed2(acc.totaleoutturn, avgeoutturn)
            }
        }, { totalpiece: 0, totalcost: 0, totalaoutturn: 0, totaleoutturn: 0 })

        const avgaoutturn = totalaoutturn / cuttingData.length
        const avgeoutturn = totaleoutturn / cuttingData.length
        const diffinoutturn = numToFixed2(avgaoutturn - avgeoutturn)
        const diffinoutturnper = (Number(avgeoutturn) && Number(totaleoutturn)) ? numToFixed2(((avgaoutturn / avgeoutturn) * 100), -100) : 0.00

        let title = writeTextForPDF(`Employee : ${employeeWiseData.empname}`, { fontSize: 18 })

        if (index > 0) title.pageBreak = 'before'

        const getTableBody = () => {
            return employeeWiseData.jobs.map((cuttingJob) => {
                const { completedon, garment, fabric, sizesname, color, qty, totalpiece, avgaoutturn, avgeoutturn, totalcost } = cuttingJob
                const diffinoutturn = numToFixed2(avgaoutturn - avgeoutturn)
                const fontColor = (Number(diffinoutturn) >= 0) ? 'green' : 'red'
                return [
                    writeTextForPDF(completedon, {}),
                    writeTextForPDF(garment, {}),
                    writeTextForPDF(fabric, {}),
                    writeTextForPDF(sizesname, {}),
                    writeTextForPDF(color, {}),
                    writeTextForPDF(qty, {}),
                    writeTextForPDF(totalpiece, {}),
                    writeTextForPDF(totalcost, { bold: true }),
                    writeTextForPDF(avgaoutturn, { color: fontColor }),
                    writeTextForPDF(avgeoutturn, {}),
                ]
            })
        }

        const content = [
            title,
            {
                table: {
                    headerRows: 1,
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                        //header
                        [
                            { text: 'Date', bold: true },
                            { text: 'Item', bold: true },
                            { text: 'Fabric', bold: true },
                            { text: 'Size', bold: true },
                            { text: 'Color', bold: true },
                            { text: 'Weight', bold: true },
                            { text: 'Piece', bold: true },
                            { text: 'Cost', bold: true },
                            { text: 'Out Turn', bold: true },
                            { text: 'E.Out Turn', bold: true },
                        ],
                        //table Body
                        ...getTableBody(),
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        if (i === 0)
                            return 1
                        if (i === node.table.headerRows)
                            return 1;
                        if (i === node.table.body.length)
                            return 1;
                        return 0;
                    },
                    vLineWidth: function () {
                        return 1;
                    },
                }
            },
            {
                text: '\nSummary',
                decoration: 'underline',
                bold: true,
            },
            {
                text: [
                    '\nTotal Cost : ',
                    { text: totalcost, bold: true }
                ]
            },
            {
                text: [
                    'Total Piece : ',
                    { text: totalpiece, bold: true }
                ]
            },
            {
                text: [
                    'Avg Out Turn : ',
                    { text: numToFixed2(avgaoutturn), bold: true }
                ]
            },
            {
                text: [
                    'Avg E.Out Turn : ',
                    { text: numToFixed2(avgeoutturn), bold: true }
                ]
            },
            {
                text: [
                    'Diff In Out Turn : ',
                    diffinoutturn,
                    ' per KG | ',
                    diffinoutturnper,
                    ' %'
                ],
                color: (diffinoutturn >= 0) ? 'green' : 'red',
            },
        ]
        return content
    })

    return {
        header: function () {
            return { text: 'Cutting Section - Job Completion Report (Employee Wise)', alignment: 'center', bold: true, fontSize: '18' }
        },
        footer: function (currentPage, pageCount) {
            return pDFPageNumber(currentPage, pageCount, { bold: true })
        },
        content: employeeWiseContent,
    }

}
