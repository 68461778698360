export const generateClassNameStyle = (style = []) => {
    if (style && style.length) {
        return style.reduce((acc, sty) => {
            if (sty === 'bold')
                acc += ' f--bold'
            else if (sty === 'strike')
                acc += ' f--strike'
            else if (sty === 'italic')
                acc += ' f--italic'
            else if (sty === 'underline')
                acc += ' f--underline'
            return acc
        }, '')
    }
    else {
        return ''
    }
}

export const generateClassNameSize = (size = '') => {
    switch (size) {
        case 'x-small': return 'f--x-small'
        case 'small': return 'f--small'
        case 'medium': return 'f--medium'
        case 'large': return 'f--large'
        case 'x-large': return 'f--x-large'
        case 'xx-large': return 'f--xx-large'
        default: return 'f--medium'
    }
}

export const generateClassNameAlignment = (alignment = '') => {
    switch (alignment) {
        case 'center': return 'a--center'
        case 'right': return 'a--right'
        case 'left': return 'a--left'
        case 'justify': return 'a--justify'
        default: return 'a--center'
    }
}

export const generateClassNameTextTransform = (texttransform = '') => {
    switch (texttransform) {
        case 'uppercase': return 'f--uppercase';
        case 'lowercase': return 'f--lowercase';
        case 'capitalize': return 'f--capitalize';
        default: return ''
    }
}

export const generateClassName = ({ style = {}, size = '', alignment = '', texttransform }) => (`${generateClassNameAlignment(alignment)} ${generateClassNameSize(size)} ${generateClassNameStyle(style)} ${generateClassNameTextTransform(texttransform)}`)

const STYLES = ['btn--default', 'btn--primary', 'btn--info', 'btn--danger', 'btn--warning', 'btn--success', 'btn--special']
const STYLESCN = ['--default--A', '--primary--A', '--info--A', '--danger--A', '--warning--A', '--success--A', '--special--A']
export const getClassNameType = (STYLE = '') => {
    const index = STYLES.findIndex(sty => sty.includes(STYLE))
    return STYLESCN[index < 0 ? 0 : index]
}
