import { stateList } from "../../../../Components/GST/stateData"
import { invoiceShipmentDefaultData } from "../../../../Components/Invoice/InvoiceShipping/invoiceShipmentDefaultData"
import { defaultItemSettings } from "../../../../Components/Settings/ItemSettings/itemSettingsDefaultData"
import { invoiceSummaryCalc } from "../../../../Recoil/Invoice/HelperFunc"
import { getDiscountedPrice, getDiscountedPriceByUnit, getPriceOnRateAndTax, getRateOnPrice } from "../../../General/DiscountCals"
import { getFormatedDate, toDateString } from "../../../General/GetDateTime"
import { numToFixed2, numToIfFixed2 } from "../../../General/numManipulation"
import { iNRWords } from "../../../General/numberToWords"
import { getLenOfObj, getLenOfObjBool } from "../../../General/objectManipulation"
import { objReduce } from "../../../General/objectManipulation"
import { writeTextForPDF } from "../PDFFunctions"

const invoiceHeaderName = {
    'salesgstinvoice': 'Tax Invoice',
    'salesquotation': 'Estimation Slip',
    'salesreturngstinvoice': 'Credit Note',
    'salesreturnquotation': 'Return Estimation',
    'purchasereturngstinvoice': 'Debit Note',
    'purchasereturnquotation': 'Purchase Return Estimation',
}

const a4itemsbyfontsize = {
    10: {
        single: 14,
        first: 33,
        mid: 33,
        last: 11,
    },
}

export const invoiceReceiptA4_1 = (data) => {

    const {
        invoiceData = {},
        coll = 'sales',
        receiptSettings = {},
        itemSettings = defaultItemSettings,
        businessData = {},
        defaultUnits = {},
        allBankData = {},
        businessLogo = '',
    } = data

    const { invoicetype = 'quotation', } = invoiceData


    const pageHeader = () => {
        return [
            { text: invoiceHeaderName?.[`${coll}${invoicetype}`] ?? 'Receipt', alignment: 'center', bold: true, margin: [0, 10, 0, 0], fontSize: 12 },
        ]
    }

    const pageFooter = (currentPage, pageCount) => {
        const { footer = {} } = receiptSettings
        const { subjected = '', adddata = '', adddata2 = '' } = footer
        return [
            {
                columns: [
                    { text: `${subjected ? `Subjected to ${subjected} Jurisdiction` : `${adddata2 || ''}`}`, alignment: 'left', margin: [10, 0, 0, 0] },
                    { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [10, 0, 10, 0] },
                ]
            },
            {
                columns: [
                    { text: `${adddata ? adddata : ''}`, alignment: 'left', margin: [10, 0, 0, 0] },
                    { text: 'Computer Generate Invoice.', alignment: 'left', margin: [10, 0, 0, 0], width: 150 },
                ]
            },
        ];
    }

    const pageWaterMark = () => {
        const { watermark = '' } = receiptSettings
        if (watermark)
            return { text: 'GK Angadi', opacity: 0.2, color: '#25356b' }
        return { text: '' }
    }

    const planPDFByItemCount = () => {
        const invoiceSummary = invoiceSummaryCalc(invoiceData.softInvoiceItems)
        const { softInvoiceItems = [], invoicegstin, invoiceCustomerData, invoicetype } = invoiceData
        const invoicestatecode = (invoicegstin + '').substring(0, 2)
        const customerstatecode = invoiceCustomerData.customergst ? (invoiceCustomerData.customergst + '').substring(0, 2) : ''
        // const isgst = (invoicetype === 'gstinvoice') || forcegstformat
        const isigst = customerstatecode ? (invoicestatecode !== customerstatecode) : false

        const invoiceHeader = () => {
            const invoiceBusinessDataPDF = () => {
                const { invoicegstin } = invoiceData
                const { gSTData = {}, } = businessData
                const { businessname, address, mobile, email } = gSTData?.[invoicegstin] ?? {}

                const stack = []
                stack.push({
                    text: businessname ?? businessData.businessname ?? '',
                    italics: true,
                    bold: true, fontSize: 12
                })
                if (address)
                    stack.push({ text: address })
                if (invoicegstin) {
                    const statecode = (invoicegstin + '').substring(0, 2)
                    stack.push({ text: 'GSTIN : ' + invoicegstin, bold: true })
                    stack.push({ text: 'State Code :' + statecode + ' (' + (stateList[statecode]) + ')', bold: true })
                }
                if (mobile)
                    stack.push({ text: 'Mobile : ' + mobile })
                if (email)
                    stack.push({ text: 'Email : ' + email })
                return { stack }
            }

            const invoiceDataPDF = () => {
                const { ibun = '', invoicenumber = '', timestamp = '', ots = '', oid = '', invoiceAdditionalData } = invoiceData
                return {
                    stack: [
                        // {
                        //     text: 'Invoice Details : ',
                        //     bold: true
                        // },
                        { text: 'Invoice No: ' + invoicenumber, bold: true, fontSize: 11 },
                        { text: 'Date : ' + getFormatedDate('dd-mm-yyyy', timestamp), bold: true, fontSize: 11 },
                        'Invoice By : ' + (invoiceAdditionalData?.invoiceby || ibun || ''),
                        'Order ID & Date: ' + (oid ? (oid + ' & ') : ' - ') + (ots ? toDateString(ots) : ''),
                    ],
                    // margin:[0,50,0,0],
                    // border: [false, true, true, true],
                }
            }

            const invoiceCustomerDataPDF = () => {
                const { invoiceCustomerData = {}, invoicegstin = '', customerAdditionalData = {} } = invoiceData
                const { customername, customermobile, customergst, customeraddress, } = invoiceCustomerData

                const stack = []
                // const stack = [{ text: customername ? 'Buyer' : 'Guest' }]
                // if (customername)
                stack.push({
                    text: customername || 'Guest',
                    italics: true,
                    bold: true, fontSize: 11
                })
                if (customeraddress)
                    stack.push({ text: customeraddress })
                if (customergst) {
                    const statecode = (customergst + '').substring(0, 2)
                    stack.push({ text: 'GSTIN : ' + customergst, bold: true })
                    stack.push({ text: 'State Code :' + statecode + ' (' + (stateList[statecode]) + ')', bold: true })
                }
                if (customermobile)
                    stack.push({ text: 'Mobile : ' + customermobile })


                const { model, brand, regno, km } = customerAdditionalData
                if (model || brand) {
                    stack.push({ text: `${brand} ${model}` })
                }
                if (regno)
                    stack.push({ text: `RegNo: ${regno}` })
                if (km)
                    stack.push({ text: `KM: ${km}` })
                return { stack }
            }

            const invoiceShipmentDataPDF = () => {
                const { shipmentData = invoiceShipmentDefaultData, invoiceCustomerData } = invoiceData
                const { same, saddress, vno } = shipmentData
                return {
                    stack: [
                        { text: 'Shipping Address :', bold: true, fontSize: 11 },
                        { text: same ? (!!invoiceCustomerData.customeraddress ? 'Same as billing address' : '') : writeTextForPDF(saddress) },
                        { text: 'Vehicle : ' + vno, margin: [0, 5, 0, 0], bold: true }
                    ],
                }
            }

            const logo = businessLogo ? [{
                image: businessLogo,
                width: 70,
                height: 70,
                // height: 144,
                // absolutePosition: { x: 480, y: 160 },
                alignment: 'left',
            }] : []
            // const logo = []

            return {
                table: {
                    widths: ['*', 180],
                    heights: ['*', 80],
                    body: [
                        [
                            {
                                columns: [
                                    ...logo,
                                    invoiceBusinessDataPDF(),
                                ],
                            },
                            invoiceDataPDF(),
                        ],
                        [
                            invoiceCustomerDataPDF(),
                            invoiceShipmentDataPDF(),
                        ],
                    ]
                }
            }
        }

        const invoiceItemsPDF = (items = [], sno = 0, itemscount = 15, islast = false) => {
            const getItemsHeader = () => {
                return [
                    { text: 'S.NO', bold: true },
                    { text: 'Description Of Goods', bold: true },
                    { text: 'HSN/SAC', bold: true },
                    { text: 'GST%', bold: true },
                    // { text: 'CESS Rate', bold: true },
                    { text: 'Quantity', bold: true },
                    // [
                    //     { text: 'Rate', bold: true },
                    //     { text: 'Incl.GST', italic: true },
                    // ],
                    { text: 'Rate', bold: true },
                    { text: 'Dis', bold: true },
                    { text: 'Per', bold: true },
                    { text: 'Amount', bold: true }
                ]
            }

            const getItemsArray = () => {
                return items.reduce((acc, crr, index) => {
                    const { name, des, pcode, hsncode, gstrate, cessrate, quantity, price, unitid, discount, mrp, } = crr
                    const unitname = defaultUnits?.[unitid]?.unitname ?? ''
                    // const total = getDiscountedPriceByUnit(price, discount, quantity)
                    const totaltaxrate = Number(Number(gstrate) + Number(cessrate)).toFixed(2)
                    const rate = getDiscountedPrice(getRateOnPrice(price, totaltaxrate))
                    const disrate = getDiscountedPrice(getRateOnPrice(getDiscountedPrice(price, discount), totaltaxrate))
                    const total = disrate * quantity
                    // const rate = getDiscountedPrice(getRateOnPrice(price, numToIfFixed2(gstrate, cessrate)))
                    // const total = getDiscountedPriceByUnit(rate, discount, quantity)
                    return [
                        ...acc,
                        [
                            { text: index - (-1) - (-sno), alignment: 'right' },
                            { text: writeTextForPDF(name + (des ? ('\n' + des) : '') + (pcode ? ('\n' + pcode) : '')) },
                            { text: hsncode },
                            { text: numToIfFixed2(gstrate) + '%', alignment: 'right' },
                            // { text: Number(cessrate).toFixed(2), alignment: 'right' },
                            { text: Number(quantity).toFixed(3), alignment: 'right' },
                            // { text: Number(price).toFixed(2), alignment: 'right' },
                            { text: rate, alignment: 'right' },
                            { text: Number(discount) ? `${Number(discount).toFixed(2)}%` : '', alignment: 'right' },
                            { text: unitname },
                            { text: Number(total).toFixed(2), alignment: 'right' }
                        ]
                    ]
                }, [])
            };

            const emptyLines = () => {
                const emptyline = () => ['\n', '', '', '', '', '', '', '', '\n']
                let emptyLinesArr = []
                if (items.length < itemscount) {
                    const emptylinecount = itemscount - items.length - taxCount - 1
                    emptyLinesArr = Array.from({ length: emptylinecount }, () => '').map(() => emptyline())
                }
                return emptyLinesArr
            }

            const isLast = () => {
                const { invoicetotaltax, invoicetotal, invoiceroundoffdiff, invoicegrosstotal } = invoiceSummary
                const taxColumns = (isigst ? [{ n: 'igst', d: 1 }] : [{ n: 'cgst', d: 2 }, { n: 'sgst', d: 2 }]).map(cld => ['', '', '', '', { text: '@' + cld.n, alignment: 'right', bold: true }, '', '', '', { text: numToFixed2(invoicetotaltax / cld.d), bold: true, alignment: 'right' }])
                if (islast) {
                    return [
                        ['\n', '', '', '', '', '', '', '', ''],
                        ...taxColumns,
                        ['', '', '', '', { text: 'Round Off', alignment: 'right', bold: true }, '', '', '', { text: (invoiceroundoffdiff > 0 ? '+' : '') + '' + invoiceroundoffdiff, bold: true, alignment: 'right' }],
                        // ['', '', '', '', { text: '@cgst', alignment: 'right', bold: true }, '', '', '', '', { text: numToFixed2(invoicetotaltax / 2), bold: true, alignment: 'right' }],
                        // ['', '', '', '', { text: '@sgst', alignment: 'right', bold: true }, '', '', '', '', { text: numToFixed2(invoicetotaltax / 2), bold: true, alignment: 'right' }],
                        ['\n', '', '', '', '', '', '', '', ''],
                        [{ text: 'Total', alignment: 'right', bold: true, colSpan: 8, margin: [0, 0, 30, 0] }, {}, {}, {}, {}, { text: '', alignment: 'right', bold: true }, '', '', { text: invoicegrosstotal, bold: true, alignment: 'right' }],
                    ]
                }
                return []
            }

            return {
                table: {
                    widths: [23, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    // widths: ['*','*','*','*','*','*','*','*','*'],
                    headerRows: 1,
                    body: [
                        getItemsHeader(),
                        ...getItemsArray(),
                        ...emptyLines(),
                        ...isLast()
                    ],
                },
                layout: {
                    hLineWidth: function (i, node) {
                        if (i === 0)
                            return 1
                        if (i === node.table.headerRows)
                            return 1;
                        if (i === node.table.body.length)
                            return 1;
                        if ((i === node.table.body.length - 1) && (islast))
                            return 1;
                        return 0;
                    },
                    vLineWidth: function () {
                        return 1;
                    },
                }
            }
        }

        const invoiceFooter = () => {
            const { invoicetotal, invoicetotaltax } = invoiceSummary


            const invoiceAmountInWords = (amount = 1000, istaxamount = false,) => {
                return [
                    { text: istaxamount ? 'Tax Amount (in words) :' : 'Amount Chargeable (in words) :' },
                    { text: iNRWords(amount), bold: true }
                ]
            }

            const invoiceTaxPDF = () => {
                const getTaxArray = () => {
                    const { invoiceratebasedtax } = invoiceSummaryCalc(invoiceData.softInvoiceItems)

                    return objReduce(invoiceratebasedtax, (acc, taxrate) => {
                        const { cgst, sgst, gst, totaltax, taxableamount } = invoiceratebasedtax[taxrate]
                        const taxcolumns = (isigst ? [1] : [2, 2]).reduce((acc, crr) => [...acc, { text: numToFixed2(taxrate / crr) + '%', alignment: 'center' }, { text: numToFixed2(totaltax / crr), alignment: 'right' },], [])

                        return [
                            ...acc,
                            [
                                { text: taxrate + '%', alignment: 'center' },
                                { text: taxableamount, alignment: 'right' },
                                ...taxcolumns,
                                // { text: numToFixed2(taxrate / 2) + '%', alignment: 'center' },
                                // { text: numToFixed2(totaltax / 2), alignment: 'right' },
                                // { text: numToFixed2(taxrate / 2) + '%', alignment: 'center' },
                                // { text: numToFixed2(totaltax / 2), alignment: 'right' },
                                { text: numToFixed2(totaltax), alignment: 'right' },
                            ]
                        ]
                    }, [])
                }

                // return {}
                const taxheader1 = (isigst ? ['Integrated'] : ['Central', 'State']).reduce((acc, crr) => ([...acc, { text: crr + ' Tax', colSpan: 2, alignment: 'center', bold: true }, {}]), [])
                const taxheader2 = (isigst ? ['Integrated'] : ['Central', 'State']).reduce((acc, crr) => ([...acc, { text: 'Rate', alignment: 'center', bold: true }, { text: 'Amount', alignment: 'center', bold: true },]), [])
                const emptycells = Array.from({ length: (isigst ? 2 : 4) }, () => '').map(() => [' '])

                return {
                    table: {
                        headerRows: 2,
                        widths: isigst ? ['*', '*', '*', '*', '*'] : ['*', '*', '*', '*', '*', '*', '*'],
                        body: [
                            [
                                { text: 'Tax Rate', rowSpan: 2, alignment: 'center', bold: true },
                                { text: 'Taxable Value', rowSpan: 2, alignment: 'center', bold: true },
                                ...taxheader1,
                                // { text: 'Central Tax', colSpan: 2, alignment: 'center', bold: true },
                                // {},
                                // { text: 'State Tax', colSpan: 2, alignment: 'center', bold: true },
                                // {},
                                { text: 'Total Tax Amount', rowSpan: 2, alignment: 'center', bold: true },
                            ],
                            [
                                {},
                                {},
                                ...taxheader2,
                                // { text: 'Rate', alignment: 'center', bold: true },
                                // { text: 'Amount', alignment: 'center', bold: true },
                                // { text: 'Rate', alignment: 'center', bold: true },
                                // { text: 'Amount', alignment: 'center', bold: true },
                                {},
                            ],
                            ...getTaxArray(),
                            [{ text: 'Total', alignment: 'right', bold: true, colSpan: isigst ? 4 : 6, margin: [0, 0, 10, 0] }, ...emptycells, '', { text: invoicetotaltax, bold: true, alignment: 'right' }],
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            if (i === 0)
                                return 1
                            if (i <= node.table.headerRows)
                                return 1;
                            if (i === node.table.body.length)
                                return 1;
                            if (i === node.table.body.length - 1)
                                return 1;
                            return 0;
                        },
                        vLineWidth: function () {
                            return 1;
                        }
                    }
                }
            }

            const invoiceFooterDecleration = () => {
                const { decleration = '' } = receiptSettings
                return {
                    stack: [
                        {
                            text: 'Decleration :',
                            // italics: true, 
                            bold: true
                        },
                        {
                            text: decleration ?? ''
                        }
                    ],
                    alignment: 'left',
                    // margin: [0,0,0,0], 
                    // border: [true, true, true, true],
                }
            }

            const invoiceFooterBankDetails = () => {
                const { bankid, invoicegstin = '' } = invoiceData
                // const bankDetails = bankid ? allBankData[bankid] : allBankData[objKeyToArr(allBankData)[0]]
                // const { bankname, accname, accno, ifsc, upiid } = bankDetails
                const gstBankData = (invoicegstin && getLenOfObjBool((businessData?.gSTData ?? {}))) ? businessData?.gSTData?.[invoicegstin] : ({ bankname: '', ifsc: '', accno: '', upi: '' })
                const { bankname = '', ifsc = '', accno = '', upi = '' } = gstBankData
                return {
                    stack: [
                        {
                            text: 'Bank Details',
                            // italics: true, 
                            bold: true
                        },
                        'Name :' + bankname,
                        // 'Account Name :' + accname,
                        'Account Number:' + accno,
                        'IFSC Code:' + ifsc,
                        'UPI ID: ' + upi,
                    ],
                    // margin:[0,50,0,0], 
                    // border: [false, true, true, true],
                }
            }

            const invoiceFooterCustomerSignatory = () => {
                return {
                    text: 'Customer\'s Seal and Signature',
                    alignment: 'left',
                    // margin: [0,0,0,0], 
                    // border: [true, true, true, true],
                }
            }

            const invoiceFooterBusinessSignatory = () => {
                const { gSTData = {} } = businessData
                return {
                    text: `For ${gSTData?.[invoiceData?.invoicegstin || '-']?.businessname ?? businessData.businessname}\n\n\n\n\nAuthorised Signatory`,
                    alignment: 'right',
                    // margin:[0,50,0,0], 
                    // border: [false, true, true, true],
                }
            }

            return {
                // absolutePosition: { x: 40, y: 520 },
                stack: [
                    {
                        columns: [
                            { stack: [...invoiceAmountInWords(invoicetotal)], },
                            { text: 'E. & O.E', alignment: 'right', },
                        ]
                    },
                    invoiceTaxPDF(),
                    ...invoiceAmountInWords(invoicetotaltax, true),
                    {
                        table: {
                            widths: ['*', "*"],
                            heights: ['*', 80],
                            body: [
                                [
                                    invoiceFooterDecleration(),
                                    invoiceFooterBankDetails(),
                                ],
                                [
                                    invoiceFooterCustomerSignatory(),
                                    invoiceFooterBusinessSignatory(),
                                ],
                            ]
                        }
                    }
                ]
            }
        }

        // const { softInvoiceItems = [] } = invoiceData
        const { invoiceratebasedtax } = invoiceSummary
        const items = [...softInvoiceItems]
        const itemCount = items.length
        const taxCount = getLenOfObj(invoiceratebasedtax)
        const totalCount = itemCount + taxCount

        if (totalCount <= a4itemsbyfontsize[10].single) {
            return [
                invoiceHeader(),
                invoiceItemsPDF(items, 0, 14, true),
                invoiceFooter(),
            ]
        }
        let pdfPages, i = 0
        while (i < totalCount) {
            if (i === 0) {
                const itemsToPass = items.splice(0, a4itemsbyfontsize[10].first)
                pdfPages = [
                    invoiceHeader(),
                    invoiceItemsPDF(itemsToPass, i, a4itemsbyfontsize[10].first)
                ]
                i -= -itemsToPass.length
            }
            else if ((totalCount - i) > a4itemsbyfontsize[10].last) { //to generate mid portion
                const itemsToPass = items.splice(0, a4itemsbyfontsize[10].mid)
                pdfPages = [
                    ...pdfPages,
                    { pageBreak: 'before', ...invoiceHeader() },
                    invoiceItemsPDF(itemsToPass, i, a4itemsbyfontsize[10].mid)
                ]
                i -= -itemsToPass.length
            }
            else {
                pdfPages = [
                    ...pdfPages,
                    { pageBreak: 'before', ...invoiceHeader() },
                    invoiceItemsPDF(items.splice(0, itemCount), i, (a4itemsbyfontsize[10].last - taxCount), true),
                    invoiceFooter(),
                ]
                i = totalCount
            }
        }
        return pdfPages
    }

    const dd = {
        footer: function (currentPage, pageCount) {
            return pageFooter(currentPage, pageCount)
        },
        header: function (currentPage, pageCount, pageSize) {
            return pageHeader(currentPage, pageCount, pageSize)
        },
        watermark: pageWaterMark(),
        content: [
            // invoiceHeader(),
            // invoiceItemsPDF(),
            // invoiceFooter(),
            ...planPDFByItemCount()
        ],
        defaultStyle: {
            fontSize: 10,
            margin: 5,
        }
    }

    return dd

}
