import React, { useEffect, useMemo } from 'react'
import { BiColumns } from 'react-icons/bi'
import { ReactModal } from '../../../Utils'
import CheckBox from '../CheckBox/CheckBox'

export default function ReactTableColumnSelection(props) {
    const { allColumns, hiddenColumns, lsname } = props
    const allColForls = useMemo(() => allColumns.reduce((acc, col) => ({ ...acc, [col.id]: true }), {}), [])

    useEffect(() => {
        const hidCol = hiddenColumns.reduce((acc, hc) => (acc[hc] ? ({ ...acc, [hc]: false }) : ({ ...acc })), { ...allColForls })
        localStorage.setItem(`${lsname}columns`, JSON.stringify(hidCol))
    }, [hiddenColumns])

    return (
        <div>
            <b>Column Show/Hide</b>
            {allColumns.map((column, index) => {
                if (index === 0) return
                return (
                    <div key={column.id}>
                        <CheckBox {...column.getToggleHiddenProps()} label={column.Header} disabled={!(column.roleauthorized ?? true)} />
                    </div>)
            })}
        </div>
    )
}

export function ReactTableColumnSelectionModal(props) {
    const defaultTooltip = { content: 'Column Select/Hide', direction: 'top', type: 'primary' }

    return (
        <ReactModal
            {...props}
            component={<ReactTableColumnSelection />}
            buttontext={<BiColumns />}
            buttonSize={'btn--small'}
            buttonStyle={'btn--default'}
            tooltip={{ ...defaultTooltip }}
        />
    )
}
