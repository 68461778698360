export default function componentLoader(lazyComponent, attempts = 3, interval = 1000) {

    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch(err => {
                setTimeout(() => {
                    if (attempts <= 1) {
                        if (process.env.NODE_ENV !== 'development') {
                            reject(err)
                            console.error('Component Loading Error : ', { err, attempts, lazyComponent });
                            window.location.href = '/offline.html'
                        }
                        return window.alert('Internet Connection Required.\nClose App And Open With Active Internet Connection\nIf Still Facing issue, Fix Problem in Offline Page.')
                    }
                    componentLoader(lazyComponent, attempts - 1).then(resolve, reject)
                }, interval)
            })
    })

}