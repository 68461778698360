import { atom } from "recoil";

export const defaultsFromDbState = atom({
    key: 'defaultsFromDbState',
    default: {}
})

export const defaultSaleUnitsState = atom({
    key: 'defaultSaleUnitsState',
    default: {},
})

export const paymentsDBState = atom({
    key: 'paymentsDBState',
    default: { cash: { name: 'cash' }, card: { name: 'Card/UPI' } }
})

export const pdfDataState = atom({
    key: 'pdfDataState',
    default: {
        type: '',
        filename: '',
        data: {},
        category: '',
        reporttype: '',
        islandscape: false,
        dd: {}, //optional
        pageSize: '',
    }
})
