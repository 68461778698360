import { generateId, generateRandomId } from "../../../../Utils/General/generateRandom";

export const thermalReceiptHeaderFooterInitialData = () => ({
    id: generateRandomId(5),
    data: '',
    style: [],
    size: 'medium',
    alignment: 'center',
    isenabled: true,
})
