import React from 'react'
import LoadLatest from './LoadLatest';
import { ToastContainer } from 'react-toastify'
import CheckOnline from './CheckOnline';
// import PDFAction from '../../Utils/CustomeComponent/PDF/PDFAction';
import "react-toastify/dist/ReactToastify.css";
import '../../Utils/CommonCss/NavBar.css'
import '../../Utils/CommonCss/MenuBar.css'
import '../../Utils/CommonCss/ReactTable.css'
import '../../Utils/CommonCss/Button.css'
import '../../Utils/CommonCss/Div.css'
import '../../Utils/CommonCss/General.css'
import '../../Utils/CommonCss/FormField.css'
import '../../Utils/CommonCss/Tooltip.css'
import './StartUp.css'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { base64Fonts } from './vfs_fonts';
import PDFAction2 from '../../Utils/CustomeComponent/PDF/PDFAction2';

pdfMake.vfs = { ...pdfFonts.pdfMake.vfs, ...base64Fonts };
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function StartUp() {
    return (
        <>
            <LoadLatest />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <CheckOnline />
            {/* <PDFAction /> */}
            <PDFAction2 />
        </>
    )
}

export default StartUp
