import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { pdfDataState } from '../../../Recoil/Defaults/Atoms'
import { getLenOfObjBool } from '../../General/objectManipulation'
import FullPageLoading from '../Loading/FullPageLoading'
import usePDFContent from './PDFContent/usePDFContent'
import useShare from '../Share/useShare'
import PDFVIew from './PDFVIew'
import ReactModal from '../ReactModal/ReactModal'
import { useReactToPrint } from 'react-to-print'
import pdfMake from 'pdfmake/build/pdfmake';
import ReactToast from '../ReactToast'

const fonts = {
    Uni: {
        normal: 'CODE2000.TTF',
        bold: 'CODE2000.TTF',
        italics: 'CODE2000.TTF',
        bolditalics: 'CODE2000.TTF'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
}

pdfMake.fonts = fonts;

export default function PDFAction2() {
    const pDFData = useRecoilValue(pdfDataState)
    const resetPDFData = useResetRecoilState(pdfDataState)
    const [pDFFile, setPDFFile] = useState(null)
    const iframeRef = useRef(null)
    const objectRef = useRef(null)

    const docRef = useRef(null)

    const getDocDef = usePDFContent()

    const share = useShare()

    const reset = () => {
        resetPDFData()
    }

    useEffect(() => {
        const generatePDF = async () => {
            try {
                if (getLenOfObjBool(pDFData.data)) {
                    let docDef = getDocDef(pDFData.data, pDFData.category, pDFData.reporttype)
                    if (pDFData.islandscape)
                        docDef.pageOrientation = 'landscape'

                    if (pDFData.pageSize)
                        docDef.pageSize = pDFData.pageSize

                    const pdfDocGenerator = pdfMake.createPdf(docDef);

                    if (pDFData.type === 'download')
                        return pdfDocGenerator.download(pDFData.filename || 'GK Angadi.pdf', reset)

                    if (pDFData.type === 'orgprint') {
                        reset()
                        return pdfDocGenerator.print()
                    }

                    const saveFile = () => {
                        return pdfDocGenerator.getDataUrl(dataURL => {
                            setPDFFile(dataURL)
                        })
                    }

                    const openFile = () => {
                        // if (process.env.NODE_ENV === 'development')
                        //     return saveFile()
                        reset()
                        return pdfDocGenerator.open()
                    }

                    if (pDFData.type === 'share')
                        return pdfDocGenerator.getDataUrl(async (dataURL) => {
                            await share({ dataURL, type: 'application/pdf', format: 'pdf' }, reset, openFile)
                        })

                    if (pDFData.type === 'raw') return openFile()
                    if (pDFData.type === 'open') return openFile()

                    return saveFile()
                }
            }
            catch (err) {
                reset()
                console.error('Error', err);
                ReactToast('warning', 'Something Went Wrong')
            }
        }

        generatePDF()

        return () => {
            setPDFFile(null)
        }

    }, [pDFData.data])

    const handlePrint = useReactToPrint({
        content: () => {
            return docRef.current
        },
        onAfterPrint: () => reset(),
    })

    if (!getLenOfObjBool(pDFData.data))
        return ''

    if (!pDFFile) return <FullPageLoading />

    const onPDFLoadSuccess = () => {
        setTimeout(() => handlePrint(), 1000)
    }

    switch (pDFData.type) {
        case 'print': {
            return <>
                <FullPageLoading isopen={true} />
                <div style={{ display: 'none' }}>
                    <div ref={docRef} >
                        <PDFVIew url={pDFFile} onLoadSuccess={() => onPDFLoadSuccess()} />
                    </div>
                </div>
            </>
        }
        case 'view': {
            return <ReactModal
                isopen={true}
                onClose={reset}
            >
                <PDFVIew url={pDFFile} />
            </ReactModal>
        }

        //rawmodal
        // default: {
        case 'rawmodal': {
            return <ReactModal
                isopen={true}
                onClose={reset}
            >
                <iframe
                    title="pdf-frame"
                    // ref={iframeRef}
                    style={{ width: '100%', height: '80vh', border: 'none' }}
                    src={pDFFile}
                />
            </ReactModal>
        }
        //view in modal (not a pdf format)
        default: return (process.env.NODE_ENV === 'development') ?
            <ReactModal
                isopen={true}
                onClose={reset}
            >
                <iframe
                    title="pdf-frame"
                    // ref={iframeRef}
                    style={{ width: '100%', height: '80vh', border: 'none' }}
                    src={pDFFile}
                />
            </ReactModal>
            : <ReactModal
                isopen={true}
                onClose={reset}
            >
                <PDFVIew url={pDFFile} />
            </ReactModal>
    }
}

//to print within from iframe
// #printPdf {
//     position: fixed; top: 0px; left: 0px; display: block;
//     padding: 0px; border: 0px; margin: 0px;
//     visibility: hidden; opacity: 0;
// }
// Finally, just call:

// if ('safari') {
//     pdfMake.createPdf(content).open({}, window.frames['printPdf']);
//     setTimeout(function () {
//         window.frames['printPdf'].focus();
//         window.frames['printPdf'].print();
//     }, 2000)
// } else {
//     pdfMake.createPdf(content).print({}, window.frames['printPdf']);
// }