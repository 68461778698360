import React from 'react'
import ReactTableContainer from './ReactTableContainer'

function ReactTable({ COLUMNS = [], DATA = [], noRowMsg = 'No Data Found', ColourComp, ...props }) {

    if (!COLUMNS.length) return <b>Select Columns</b>
    if (!DATA.length) return <b>{noRowMsg}</b>

    return <>
        <ReactTableContainer COLUMNS={COLUMNS} DATA={DATA} {...props} />
        {
            ColourComp ? <ColourComp /> : ''
        }
    </>

}

export default ReactTable
