import React from 'react'
import { useLocation } from 'react-router-dom'

export default function useQuery(key = 'action') {
    const { search } = useLocation()

    return React.useMemo(() => {
        const query = new URLSearchParams(search)
        return query.get(key)
    }, [search])

}
