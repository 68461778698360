import React, { useEffect, useState } from 'react'
import { GrUpdate } from 'react-icons/gr'
import { IoMdDoneAll } from 'react-icons/io'
import { ReactModal } from '../../Utils'
import FullPageLoading from '../../Utils/CustomeComponent/Loading/FullPageLoading'
import VerifyAuthentication from './VerifyAuthentication'

const UpdateDialoge = () => {
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setUpdate(true)
        }, 3500)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <ReactModal
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}
            modelclass='full-loading-transparent'
            isopen={true}
            isbutton={false}
        >
            <div className="update-dialoge">
                <div className="update-dialoge-header f--bolder a--center" style={{ color: "green" }}>
                    {
                        update ?
                            <IoMdDoneAll className='f--xx-larger' />
                            :
                            <GrUpdate className='rotate-any f--xx-larger' />
                    }
                    <div className="update-dialoge-header-title f-xx-larger">
                        {update ? 'Completed' : ' Updating...'}
                    </div>
                    <div className="update-dialoge-header-other f--larger">
                        {update ? 'Reopen Application' : 'Please Wait'}
                    </div>
                </div>
                {/* <div className="update-dialoge-message">
                    Steps to update:
                    <ol>
                        <li>Refresh page. (Computer: click F5 | Mobile: swipe donw untill loading icon appers in top)</li>
                        <li>Close all instance of GK Angadi App (If Mobile : remove from recent apps list), then open again.</li>
                    </ol>
                </div>
                <br />
                <br />
                <br />
                <i>
                    <div className="update-dialoge-faq">
                        Facing Issue with update:<br />
                        Close All Browser in Computer / Close All Browser from Recent Apps in Mobile. Then Open Angadi App.
                    </div>
                </i> */}
            </div>
        </ReactModal>
    )
}

export default function LoadLatest() {
    const [update, setUpdate] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (navigator.onLine && ('caches' in window)) {
            caches.keys().then(keys => {
                const staticCount = keys.reduce((count, key) => {
                    if (key.includes('static'))
                        return ++count
                    return count
                }, 0)
                if (staticCount > 1)
                    setUpdate(true)
                return setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    }, [])

    if (loading)
        return <FullPageLoading />

    if (update)
        return <UpdateDialoge />

    return <VerifyAuthentication />
}
