import { objKeyToArr } from "../../../General/objectManipulation"
import { pDFPageNumber, writeTextForPDF } from "../PDFFunctions"

export const invoiceItemByPartySmartPDFContent = (data) => {
    const { invoices = [], from, to } = data
    const items = invoices.reduce((acc, invoice) => {
        const items = invoice.softInvoiceItems.reduce((iacc, item) => {
            if (item.saleid) {
                if (!iacc[item.saleid])
                    return { ...iacc, [item.saleid]: item.name }
            }
            return { ...iacc }
            // return {...iacc, [generateRandomId]}
        }, { ...acc })
        return items
    }, {})
    const itemsArr = objKeyToArr(items).sort((a, b) => (items[a] < items[b] ? -1 : 1)).splice(0, 9)
    const itemsLen = itemsArr.length

    const getInvoiceByParty = () => {
        return invoices.map((invoice, index) => {
            const items = invoice.softInvoiceItems
            const arr = itemsArr.map(id => {
                const item = items.find(it => it.saleid === id)
                if (item)
                    return { text: Number(item.quantity).toFixed(3), alignment: 'right' }
                return ''
            })
            return [
                { text: index + 1 },
                { text: invoice.invoicenumber },
                writeTextForPDF(invoice.invoiceCustomerData.customername || ''),
                writeTextForPDF(invoice.invoiceCustomerData.profileid || ''),
                ...arr,
                { text: Number(invoice.invoicetotal).toFixed(2), alignment: 'right' }
            ]
        })
    }

    const itemsNameHeader = itemsArr.map(id => writeTextForPDF(items[id], { bold: true }))

    const content = [
        {
            table: {
                headerRows: 2,
                body: [
                    //headers
                    [
                        { text: 'S.No', bold: true, rowSpan: 2, alignment: 'center' },
                        { text: 'Invoice No', bold: true, rowSpan: 2, alignment: 'center' },
                        { text: 'Customers', bold: true, colSpan: 2, alignment: 'center' },
                        '',
                        { text: 'Items', bold: true, colSpan: itemsLen, alignment: 'center' },
                        ...Array.from({ length: (itemsLen - 1) }, () => '').map(val => val),
                        { text: 'Total', bold: true, rowSpan: 2, alignment: 'center' }
                    ],
                    [
                        '',
                        '',
                        { text: 'Name', bold: true },
                        { text: 'Profile ID', bold: true },
                        ...itemsNameHeader,
                        ''
                    ],
                    ...getInvoiceByParty()
                ]
            }
        }
    ]

    return {
        header: {
            text: [
                { text: 'Invoice Summary', alignment: 'center', bold: true, margin: [0, 10, 0, 0] }
            ]
        },
        footer: function (currentPage, pageCount) {
            return pDFPageNumber(currentPage, pageCount)
        },
        content,
    }
}

export const invoicePDFContent = (data) => {
    const { invoiceCustomerData, invoiceItems, timestamp, edt = {}, invoicesaletype, invoicetype = 'gstinvoice', invoicecategory = 'sales' } = data

    const content = [

    ]

    const invoiceType = {
        'salesgstinvoice': 'Tax Invoice',
        'salesquotation': 'Estimation Slip',
        'salesreturngstinvoice': 'Credit Note',
        'salesreturnquotation': 'Return Estimation',
        'purchasereturngstinvoice': 'Debit Note',
        'purchasereturnquotation': 'Purchase Return Estimation',
    }

    return {
        header: {
            text: [
                { text: 'Invoice', alignment: 'center', bold: true, margin: [0, 10, 0, 0] }
            ]
        },
        footer: function (currentPage, pageCount) {
            return pDFPageNumber(currentPage, pageCount)
        },
        content,
    }

}

export const invoiceItemsPDFContent = (data) => {
    const { invoices, from, to } = data
    const content = []

    return {
        header: {
            text: [
                { text: 'Invoice Summary', alignment: 'center', bold: true, margin: [0, 10, 0, 0] }
            ]
        },
        footer: function (currentPage, pageCount) {
            return pDFPageNumber(currentPage, pageCount)
        },
        content,
    }
}
