import React from 'react'
import "./Loading.css"

export default function Loading({ loadingtext = 'Loading' }) {

    return (
        <div className="loading-spinner-component">
            <div className='loading-spinner'>
                {loadingtext}
                <div className="spinner-sector spinner-sector-red"></div>
                <div className="spinner-sector spinner-sector-green"></div>
                <div className="spinner-sector spinner-sector-blue"></div>
            </div>
        </div>
    )
}
