import React from 'react'
import { getClassNameType } from '../../General/ClassName'

// const STYLES = ['btn--default', 'btn--primary', 'btn--info', 'btn--danger', 'btn--warning', 'btn--success', 'btn--special']

const SIZES = ['btn--medium', 'btn--large', 'btn--small', 'btn--small--round', 'btn--medium--round']

const Button = ({
    children,
    buttontext,
    type = 'button',
    onClick,
    disabled,
    buttonStyle,
    buttonSize = 'btn--small',
    style,
    className
}) => {
    // const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <button
            className={`${className ?? ''} btn btn${getClassNameType(buttonStyle)} ${checkButtonSize}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
            style={style ? { ...style } : {}}
        >
            {buttontext || children}
        </button>
    )
}

export default Button
