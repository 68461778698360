import { pDFPageNumber, writeTextForPDF } from "../PDFFunctions"

export const lineSalesStockPDFContent = (data) => {
    const { stock } = data

    const getTableBody = () => {
        return stock.map((sto, index) => [
            writeTextForPDF(index - (-1), { alignment: 'right' }),
            writeTextForPDF(sto.n, { bold: true }),
            writeTextForPDF(sto.qt, { alignment: 'right' }),
            writeTextForPDF(sto.sold, { alignment: 'right' }),
            writeTextForPDF(sto.remaining, { alignment: 'right', bold: true }),
        ])
    }

    const content = [
        {
            table: {
                headerRows: 1,
                widths: ['auto', '*', '*', '*', '*'],
                body: [
                    //header
                    [
                        { text: 'S.N0', bold: true },
                        { text: 'Name', bold: true },
                        { text: 'Picked', bold: true },
                        { text: 'Sold', bold: true },
                        { text: 'Stock', bold: true },
                    ],
                    //table Body
                    ...getTableBody(),
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    if (i === 0)
                        return 1
                    if (i === node.table.headerRows)
                        return 1;
                    if (i === node.table.body.length)
                        return 1;
                    return 0;
                },
                vLineWidth: function () {
                    return 1;
                },
            }
        }
    ]

    return {
        header: function () {
            return { text: 'Line Sales Report', alignment: 'center', bold: true, fontSize: '18' }
        },
        footer: function (currentPage, pageCount) {
            return pDFPageNumber(currentPage, pageCount, { bold: true })
        },
        content,
    }
}