import React, { lazy, useEffect, useState, Suspense } from 'react'
import { useRecoilState } from 'recoil'
import useFSDB from '../../../CustomHooks/FSDB'
import { authDataState } from '../../../Recoil/StartUp/Atoms'
import 'react-responsive-modal/styles.css';
import componentLoader from '../../../Utils/General/componentLoader';
import Loading from '../../../Utils/CustomeComponent/Loading/Loading';
const AuthApp = lazy(() => componentLoader(() => import('../../Application/AuthApp/AuthApp')))
const UnAuthApp = lazy(() => componentLoader(() => import('../../Application/UnAuthApp/UnAuthApp')))

function VerifyAuthentication() {
    const [authData, setAuthData] = useRecoilState(authDataState)
    const [loading, setLoading] = useState(true)

    const { fauth } = useFSDB()

    useEffect(() => {
        const authListener = fauth.onAuthStateChanged(userAuth => {
            if (userAuth) {
                const userAuthJSON = JSON.parse(JSON.stringify(userAuth))
                setAuthData({ uid: userAuth.uid, lastloginat: userAuthJSON.lastLoginAt, mobilenumber: (userAuth.phoneNumber + '').substring(3), token: userAuthJSON.stsTokenManager.accessToken })
            }
            else
                setAuthData({})
            setLoading(false)
        })
        return () => {
            authListener();
            setAuthData({});
        }
    }, [])

    return (
        <Suspense fallback={<Loading loadingtext='Loading' />}>
            {
                !loading &&
                <>
                    {
                        authData.uid ? <AuthApp /> : <UnAuthApp />
                    }
                </>
            }
        </Suspense>
    )
}

export default VerifyAuthentication
