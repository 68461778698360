export const invoiceGeneralSettingDefaultData = {
    allowsalebelowpp: false,
    allowduplicateitem: false,
    allowunsaveditem: false,
    allowonlyifinstock: false,
    allowselfinvoiceno: false,
    resetquoteno: true,
    maxquantity: 1000,
}

export const invoiceShorthandSettingDefaultData = {
    setminquantity: true,
    selectitemontabkey: true,
    autoadditem: true,
    defaultfocusoncustomer: false,
    searchfilterlimit: 25,
    adjustdiscountbytotal: true,
    adjustratebytax: true,
}

export const invoiceFormatSettingDefaultData = {
    format: 'default',
    seperateforservice: false,
    summary: false,
    alwaysgst: false,
}

export const invoiceLoactionSettingDefaultData = {
    trackinvoicelocation: false,
    saveifinsameloc: false,
}

export const invoiceBarcodeSettingDefaultData = {
    isenabled: true,
    autoadditem: true,
    batchwisescan: false,
}

export const invoiceGridSettingDefaultData = {
    isenabled: false,
    showallitem: true,
}

export const invoicePaymentSettingDefaultData = {
    isenabled: false,
    checktotal: true,
}

export const onlineInvoiceSettingDefaultData = {
    isenabled: false,
    invoiceaction: true,
    issmsenabled: true,
    smsinitiateby: 'self',
    smsmessagetype: 'link',
    smsapi: '',
    iswaenabled: true,
    wainitiateby: 'self',
    wamessagetype: 'link',
    whatsappapi: '',
    includecredit: false,
    includepromotion: false,
}

export const invoiceTableColumnsSettingDefautData = {
    sno: false,
    service: false,
    code: true,
    name: true,
    des: false,
    pcode: false,
    category: false,
    unit: false,
    size: false,
    quantity: true,
    batchid: false,
    price: true,
    mrp: false,
    discount: false,
    discountasper: true,
    total: true,
    hsn: false,
    rate: false,
    gstrate: false,
    cessrate: false,
}

export const invoiceMasterSettingDefaultData = {
    allowifpricechanged: true,
    autosplititems: false,
}

export const purchaseMasterDefautlData = {
    isenabled: false,
    createbatch: false,
    genbarcode: false,
}
