import React from 'react'
import ReactModal from '../ReactModal/ReactModal'
import Loading from './Loading'

export default function FullPageLoading({ isopen = true, loadingtext }) {
    return (
        <ReactModal
            isopen={isopen}
            loadingtext={loadingtext}
            component={<Loading />}
            modelclass='full-loading-transparent'
            isbutton={false}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}
        />
    )
}
