import { pDFPageNumber, writeTextForPDF } from "../PDFFunctions"

export const viewCustomerPDFContent = (data) => {
    const { customers = [] } = data

    const getTableBody = () => {
        return customers.map((customer, index) => [
            writeTextForPDF(index - (-1), { alignment: 'right' }),
            writeTextForPDF(customer.customername, { bold: true, italics: true }),
            writeTextForPDF(customer.customermobile),
            writeTextForPDF(customer.customeraddress),
            writeTextForPDF(customer.route),
            writeTextForPDF(Number(customer.totalbal).toFixed(2), { bold: true, alignment: 'right' }),
            // { text: index - (-1) },
            // { text: customer.customername, bold: true, italics: true },
            // { text: customer.customermobile },
            // { text: customer.route },
            // { text: customer.totalbal || 0, bold: true, italics: true },
        ])
    }

    const content = [
        {
            table: {
                headerRows: 1,
                widths: ['auto', '*', '*', '*', '*', '*'],
                body: [
                    //header
                    [
                        { text: 'S.N0', bold: true },
                        { text: 'Name', bold: true },
                        { text: 'Mobile', bold: true },
                        { text: 'Address', bold: true },
                        { text: 'Route', bold: true },
                        { text: 'Balance', bold: true },
                    ],
                    //table Body
                    ...getTableBody(),
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    if (i === 0)
                        return 1
                    if (i === node.table.headerRows)
                        return 1;
                    if (i === node.table.body.length)
                        return 1;
                    return 0;
                },
                vLineWidth: function () {
                    return 1;
                },
            }
        }
    ]

    return {
        header: function () {
            return { text: 'Customer Report', alignment: 'center', bold: true, fontSize: '18' }
        },
        footer: function (currentPage, pageCount) {
            return pDFPageNumber(currentPage, pageCount, { bold: true })
        },
        content,
    }
}
