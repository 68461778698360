export const convertTimestamp = (timestamp) => {
    if (timestamp) {
        if ((timestamp?.seconds) || (timestamp?._seconds))
            return new Date((timestamp.seconds ?? timestamp._seconds) * 1000)
        if (!isNaN(new Date(Number(timestamp))))
            return new Date(Number(timestamp))
        if (!isNaN(new Date(timestamp)))
            return new Date(timestamp)
    }
    return new Date()
}

export const numTimestamp = (timestamp) => Number(convertTimestamp(timestamp))

export const getTimestamp = () => new Date()

export const toTimestampString36 = (timestamp) => Number(convertTimestamp(timestamp)).toString(36)

export const toDateString = (timestamp = getTimestamp()) => new Date(convertTimestamp(timestamp)).toDateString()

const toHour = (timestamp) => new Date(convertTimestamp(timestamp)).setHours(new Date(convertTimestamp(timestamp)).getHours(), 0, 0, 0)
export const toHourString = (timestamp = getTimestamp()) => toLocaleString(toHour(timestamp))

export const toLocaleDateString = (timestamp = getTimestamp()) => new Date(convertTimestamp(timestamp)).toLocaleDateString()

export const toTimeString = (timestamp = getTimestamp()) => new Date(convertTimestamp(timestamp).toTimeString())

export const toLocaleTimeString = (timestamp = getTimestamp()) => new Date(convertTimestamp(timestamp)).toLocaleTimeString()

export const toLocaleString = (timestamp = getTimestamp()) => new Date(convertTimestamp(timestamp)).toLocaleString()

export const toDateStringTimestamp = (timestamp = getTimestamp()) => new Date(toDateString(timestamp))

export const toDateString36 = (timestamp) => Number(toDateStringTimestamp(timestamp)).toString(36)

export const toMonthTimestamp = (timestamp) => new Date(new Date(toDateString(timestamp)).setDate(1))

export const toMonthString36 = (timestamp) => Number(toMonthTimestamp(timestamp)).toString(36)

export const toHourStringTimestamp = (timestamp) => new Date(toHour(timestamp))

export const compareTimestamp = (a, b) => Number(convertTimestamp(a)) === Number(convertTimestamp(b))

export const sortTimestamp = (a, b) => convertTimestamp(a) - convertTimestamp(b)

export const isBetweenTimestamp = (ts, min, max) => (sortTimestamp(ts, min) >= 0) && (sortTimestamp(max, ts) >= 0)

export const isToday = (timestamp) => !!timestamp ? compareTimestamp(toDateStringTimestamp(timestamp), toDateStringTimestamp()) : false

export const addDaysToTimestamp = (days, tstamp) => {
    const timestamp = convertTimestamp(tstamp)
    return new Date(timestamp).setDate(new Date(timestamp).getDate() - (-Number(days)))
}

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
];

export const getFormatedDate = (format, ts) => {
    const timestamp = convertTimestamp(ts)
    const date = timestamp.getDate()
    const month = (timestamp.getMonth() + 1)
    const year = timestamp.getFullYear()
    var hours24 = timestamp.getHours();
    var minutes = timestamp.getMinutes();
    var ampm = hours24 >= 12 ? 'PM' : 'AM';
    var hours = hours24 % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var seconds = timestamp.getSeconds()
    seconds = seconds < 10 ? '0' + seconds : seconds
    hours24 = hours24 < 10 ? '0' + hours24 : hours24

    if (format === 'yyyy-mm-dd')
        return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`
    if (format === 'yyyy-mm-ddT00:00')
        return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}T${hours24}:${minutes}`
    if (format === 'yyyy-mm-ddThh:mm:ss')
        return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}T${hours24}:${minutes}:${seconds}`
    if (format === 'yyyy-mm-dd hh-mm-ss')
        return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date} ${hours}:${minutes}:${seconds}${ampm}`
    if (format === 'hh-mm-ss')
        return `${hours}:${minutes}:${seconds}${ampm}`
    if (format === 'dd-mm-yyyy')
        return `${date}-${month < 10 ? `0${month}` : month}-${year}`
    if (format === 'dd-mm-yyyy hh-mm')
        return `${date}-${month < 10 ? `0${month}` : month}-${year} ${hours}:${minutes}${ampm}`
    if (format === 'dd-mm-yyyy hh-mm-ss')
        return `${date}-${month < 10 ? `0${month}` : month}-${year} ${hours}:${minutes}:${seconds}${ampm}`
    if (format === 'MMM dd')
        return `${shortMonthNames[month - 1]} ${date}`
    if (format === 'hh-mm')
        return `${hours}:${minutes}${ampm}`
    if (format === '24hh-mm')
        return `${hours24}:${minutes}`
    return `${date < 10 ? `0${date}` : date}-${month < 10 ? `0${month}` : month}-${year} ${hours}:${minutes}:${seconds}${ampm}`
}
