export const numToFixed = (num1 = 0, num2 = 0, tofixed = 0) => Number(Number(num1) - (-Number(num2))).toFixed(tofixed)

export const numToFixed2 = (num1 = 0, num2 = 0) => numToFixed(num1, num2, 2)

export const numToFixed3 = (num1 = 0, num2 = 0) => numToFixed(num1, num2, 3)

export const numToFixedSkipZero = (num1 = 0, num2 = 0, tofixed = 2) => Number(numToFixed(num1, num2, tofixed)) ? numToFixed(num1, num2, tofixed) : 0

export const numToIfFixed2 = (num1 = 0, num2 = 0) => {
    const result = numToFixed2(num1, num2)
    return (((Number(result) * 100) / 100) === (Number(result))) ? Number(result) : result
}

export const numToIfFixed3 = (num1 = 0, num2 = 0) => {
    const result = numToFixed3(num1, num2)
    return (((Number(result) * 1000) / 1000) === (Number(result))) ? Number(result) : result
}