import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

firebase.initializeApp(config)

const fdb = firebase.firestore()
const fstorage = firebase.storage()

const fauth = firebase.auth()

if (((process.env.NODE_ENV === 'development') && (process.env.REACT_APP_DB_EMULATOR === 'YES'))) {
    console.log('inside emulator');
    fdb.useEmulator(process.env.REACT_APP_DB_EMULATOR_HOST, process.env.REACT_APP_DB_EMULATOR_PORT)
}

fdb.enablePersistence({ experimentalTabSynchronization: true, synchronizeTabs: true })
    .catch((err) => {
        console.error('pers error', err);
    })

export { firebase, fdb, fauth, fstorage }
