import { selector } from "recoil";
import { getLenOfObjBool } from "../../Utils/General/objectManipulation";
import { businessDataState } from "../Business/Atom";
import { userState } from "./Atoms";

export const defaultBusinessIdState = selector({
    key: 'defaultBusinessIdState',
    get: ({ get }) => {
        const userData = get(userState)
        if (userData && Object.keys(userData).length > 0 && userData.linkedBusiness && Object.keys(userData.linkedBusiness).length > 0) {
            return Object.keys(userData.linkedBusiness).reduce((acc, businessid) => {
                if (userData.linkedBusiness[businessid] === 'default') {
                    acc = businessid
                }
                return acc
            }, '')
        }
        return ''
    }
})

export const defaultShopIdState = selector({
    key: 'defaultShopIdState',
    get: ({ get }) => {
        const { defaultshop } = get(userState)
        if (!!defaultshop) return defaultshop
        const businessData = get(businessDataState)
        if (!!businessData) {
            const { linkedShops } = businessData
            if (getLenOfObjBool(linkedShops))
                return Object.keys(linkedShops)[0]
        }
        return ''
    }
})