import { atom } from "recoil";

export const loginDataState = atom({
    key: 'loginDataState',
    default: {},
})

export const authDataState = atom({
    key: 'authDataState',
    default: { uid: '' }
})

export const signUpState = atom({
    key: 'signUpState',
    default: {}
})

export const onlineState = atom({
    key: 'onlineState',
    default: true
})

export const osState = atom({
    key: 'osState',
    default: ''
})