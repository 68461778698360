import React from 'react'
import './CheckBox.css'

function CheckBox({
    onChange,
    checked,
    name = '',
    label,
    falselabel,
    formData = {},
    labelref,
    REF,
    classname,
    labelClassName,
    inputclassname,
    disabled,
    autocomplete,
    onBlur,
}) {

    return (
        // <div className="ccheckbox-wrapper">
        <label ref={labelref} disabled={disabled} className={`ccheckbox-container ${disabled ? 'ccheckbox-container-disabled' : ''} -${!!labelClassName && labelClassName} ${classname ?? ''}`}>{(checked ?? formData[name]) ? (label ?? "") : (falselabel ?? label ?? '')}
            <input
                type="checkbox"
                name={name}
                checked={checked ?? formData[name]}
                onChange={onChange}
                ref={REF}
                disabled={disabled}
                autoComplete={autocomplete || 'off'}
                onBlur={onBlur}
            />
            <span className={`ccheckbox-checkmark ${inputclassname ?? ''}`} disabled={disabled}></span>
            {/* </div> */}
        </label>
    )
}

export default CheckBox
