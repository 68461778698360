import React, { useEffect, useState } from 'react'
import { useAsyncDebounce } from 'react-table'

function ColumnFilter({ column: { filterValue, setFilter } }) {
    const [value, setValue] = useState(filterValue)

    useEffect(() => {
        if (filterValue === undefined)
            setValue(filterValue)
    }, [filterValue])

    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 300)

    return (
        <span>
            <input
                type="text"
                placeholder="Enter text to search Column"
                value={value || ''}
                onChange={({ target: { value } }) => {
                    onChange(value)
                    setValue(value)
                }}
            />
        </span>
    )
}

export default ColumnFilter
