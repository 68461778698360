import { toTimestampString36 } from "./GetDateTime"

const caps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const small = 'abcdefghijklmnopqrstuvwxyz'
const capslength = caps.length
const numeric = '0123456789'
const numericlength = numeric.length
const capsnumeric = caps + numeric
const alphanumeric = caps + small + numeric
const alphanumberclength = alphanumeric.length
const capsnumericlenght = capsnumeric.length

export const generateRandomNumberString = (length = 1) => {
    let result = '';
    for (let i = 0; i < length; i++) {
        result += numeric.charAt(Math.floor(Math.random() * numericlength))
    }
    return result
}

export const generateRandomString = (length = 1) => {
    let result = '';
    for (let i = 0; i < length; i++) {
        result += alphanumeric.charAt(Math.floor(Math.random() * alphanumberclength))
    }
    return result
}

export const generateRandomId = (length = 11) => {
    return `${generateRandomString(length)}`
}

export const generateId = (length = 1) => {
    return `${generateRandomString(length)}${toTimestampString36()}`
}

export const generateBusinessId = () => {
    return generateId(5)
}

export const generateShopId = () => {
    return generateId(5)
}

export const generateInvoiceId = () => {
    return generateId(3)
}

export const generateItemId = () => {
    return generateId(3)
}

export const generateAvlTypeId = () => {
    return generateId(3)
}

export const generateSaleTypeId = () => {
    return generateId(3)
}

export const generateFreeItemId = () => {
    return generateId(4)
}

export const generateCustomersGroupId = () => {
    return generateId(2)
}

export const generateCustomerId = () => {
    return generateId(3)
}

export const generateUnitId = () => {
    return generateId()
}

export const generateCategoryId = () => {
    return generateId(2)
}

const replaceAt = (str = '', index = 0, replacement = '', remove = 1) => {
    return str.substring(0, index) + replacement + str.substring(index - (-remove))
}

export const getNextBillCode = (oldCode = 'BB') => {
    let result = oldCode.toUpperCase()
    let codeAt = oldCode.length - 1
    while (codeAt >= 0) {
        const crrCode = oldCode.charAt(codeAt)
        const crrCodeIndex = caps.indexOf(crrCode)
        if (crrCodeIndex === (capslength - 1)) {
            result = replaceAt(result, codeAt, 'A')
            if (codeAt === 0) {
                result = 'A' + result
                codeAt = -1
            }
            else {
                --codeAt
            }
        }
        else {
            result = replaceAt(result, codeAt, caps.charAt(crrCodeIndex - (-1)))
            codeAt = -1
        }
    }
    return result
}