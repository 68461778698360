import ReactToast from "../ReactToast";

export default function useShare() {

    const share = async (
        {
            title = '',
            text = '',
            dataURL = '',
            type = 'application/pdf',
            filename = 'gkangadi',
            format = 'pdf',
        } = { title: '', text: '', dataURL: '' },
        onShare,
        onError
    ) => {

        // if (dataURL) {
        if (navigator.share && dataURL) {
            const blob = await fetch(dataURL).then((res) => res.arrayBuffer())
                .then((buffer) => new Blob([buffer], { type: (type || 'image/png') }));
            const file = new File([blob], `${filename || 'gkangadi'}.${format || 'png'}`, { type: blob.type, lastModified: new Date() });

            // function download() {
            //     const link = document.createElement('a')
            //     const url = URL.createObjectURL(file)

            //     link.href = url
            //     link.download = file.name
            //     document.body.appendChild(link)
            //     link.click()

            //     document.body.removeChild(link)
            //     window.URL.revokeObjectURL(url)
            // }
            // download()

            const files = [file];
            if (navigator.canShare && navigator.canShare({ files })) {
                return navigator.share({ files, }).then(
                    () => onShare ? onShare() : '',
                    (error) => onError ? onError() : ''
                )
            }

            ReactToast('warning', 'Share Not Supported');
            if (onError)
                return onError()
            return;

        }
        ReactToast('warning', 'Share Not Supported');
        if (onError)
            return onError()
    }

    return share
}
