import { generateId } from "../../../Utils/General/generateRandom";

export const thermalReceiptHeaderFooterInitialData = () => ({
    id: generateId(5),
    data: '',
    style: [],
    size: 'medium',
    alignment: 'center',
    isenabled: true,
})

export const receiptInvoiceDetailsDefaultData = {
    orderdate: true,
    ordertime: true,
    invoicetype: true,
    invoicecreditdata: true,
    invoicecreditdataoncash: false,
    pricegroup: true,
    soldby: false,
    deliveredby: false,
    invoiceby: true,
    paymentreceivedby: false,
    paymentmodedata: false,
    totalinwords: true,
    totalsavings: true,
    rupeesymbol: false,
}

export const receiptSellerDefaultData = {
    logo: false,
    name: true,
    address: true,
    mobile: true,
    gst: true,
    alternatecontact: false,
    branchname: true,
    branchaddress: true,
}

export const receiptBuyerDefaultData = {
    logo: false,
    isenabled: true,
    name: true,
    defaultname: 'Customer',
    mobile: true,
    address: true,
    gst: true,
    shippingaddress: true,
    alternatecontact: false,
}

export const receiptTaxDefaultData = {
    seperatetaxdetail: true,
    taxbyhsn: true, //hsn or percentage
    taxinwords: false,
}

export const receiptTokenDefaultData = {
    // isenabled: false,
    // onlyforgroup: true,
    // pricegroupname: false,
    // seperatebycategory: false,
    // categoryname: false,
    // allitemsaretoken: false,
    printonlytoken: false,
    printtogether: false,
}

export const receiptItemsColumnsDefaultData = {
    sno: false,
    des: false,
    rate: false,
    gst: false,
    cess: false,
    saleprice: false,
    mrp: false,
    discount: false,
    unit: false,
    unitinshort: false,
    tableformat: {
        size: 'medium',
        style: {},
        alignment: 'center',
        linespacing: 160,
        texttransform: '',
    },
    tableitemtotalformat: {
        size: 'medium',
        style: {},
    },
}